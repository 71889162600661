<app-navigation>
    <main>
        <h2>Formbuilder</h2>
        <section class="formbuilder-grid">
            <mat-card class="resource-table">
                <mat-card-header>
                    <mat-card-title>Resource Type</mat-card-title>
                    <mat-paginator #resourceTypePaginator="matPaginator" [hidePageSize]="true"
                    [showFirstLastButtons]="false" [pageSizeOptions]="[5]" showFirstLastButtons style="margin-left: auto;"></mat-paginator>
                </mat-card-header>
                <mat-card-header>
                        <mat-form-field style="width: 100%; margin-right: 10px;" class="rmv-field-padding" appearance="outline">
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="resourceTypeList($event)"
                                placeholder="Ex. Microsoft.Compute/virtualMachines" #input maxlength="50" appFormInput>
                        </mat-form-field>
                        <button [disabled]="!userRoles.formbuilderCreate" mat-mini-fab="primary" color="primary" (click)="resourceTypeDialog()"
                            matTooltip="Add new Resource Type">
                            <mat-icon>add</mat-icon>
                        </button>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [ngStyle]="{'opacity': loadTable ? '1' : '0.3' }" [dataSource]="resourceTypeDataSource" matSort>
                        <caption></caption>
                        <ng-container matColumnDef="resourceType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Resource Type </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-chip color="primary" selected> {{row.resourceType}}
                                </mat-chip>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                            <td mat-cell *matCellDef="let row">
                                <button [disabled]="!userRoles.formbuilderEdit" mat-icon-button color="primary" (click)="editResourceType(row)"
                                    matTooltip="Edit Resource Type">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="resourceTypeColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: resourceTypeColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4"> No Resource Type Found With Name : {{input.value}}</td>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card>

            <mat-card class="forms-table">
                <mat-card-header>
                    <mat-card-title>Forms</mat-card-title>
                    <mat-paginator #resourceFormsPaginator="matPaginator" [hidePageSize]="true"
                    [showFirstLastButtons]="false" [pageSizeOptions]="[5]" showFirstLastButtons style="margin-left: auto;"></mat-paginator>
                </mat-card-header>
                <mat-card-header>
                        <mat-form-field style="width: 100%; margin-right: 10px;" class="rmv-field-padding" appearance="outline">
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="formsList($event)" placeholder="Ex. Windows VM" #input maxlength="50" appFormInput>
                        </mat-form-field>
                        <button [disabled]="!userRoles.formbuilderCreate" mat-mini-fab="primary" color="primary" class="right-btn" [routerLink]="['/formbuilder/new']">
                            <mat-icon>add</mat-icon>
                        </button>
                </mat-card-header>
                <mat-card-content>
                <table mat-table [ngStyle]="{'opacity': loadTable ? '1' : '0.3' }" [dataSource]="resourceFormsDataSource" matSort>
                    <caption></caption>
                    <ng-container matColumnDef="formName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Form Name </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-chip color="primary" selected> {{row.resourceDisplayName}}
                            </mat-chip>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="resourceType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Resource Type </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.resourceType}}
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
                        <td mat-cell *matCellDef="let row">
                            <button [disabled]="!userRoles.formbuilderEdit" mat-icon-button color="primary" [routerLink]="['/formbuilder/edit',row.id]">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="publish">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let row">
                            <button [disabled]="!userRoles.formbuilderPublish" mat-stroked-button
                                [color]="row.published ? 'warn' : 'primary'" (click)="togglePublishForm(row.id, !row.published)">
                                {{row.published ? 'Unpublish' : 'Publish'}}
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="archive">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Archive </th>
                        <td mat-cell *matCellDef="let element">
                          <button mat-icon-button color="warn" (click)="(element.dguid)">
                            <mat-icon>archive</mat-icon>
                          </button>
                        </td>
                      </ng-container>

                    <tr mat-header-row *matHeaderRowDef="resourceFormsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: resourceFormsColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4"> No Forms Found With Name : {{input.value}}</td>
                    </tr>
                </table>
            </mat-card-content>
            </mat-card>
        </section>
    </main>
</app-navigation>
<router-outlet></router-outlet>
