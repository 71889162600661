<app-navigation>
    <main>
      <h2>Request a New Subscription</h2>
        <section style="display: flex; justify-content: center; filter: brightness(115%);">
          <mat-progress-bar *ngIf="!loadSubscriptionForm" mode="indeterminate" value="40"></mat-progress-bar>
            <form *ngIf="loadSubscriptionForm" [formGroup]="subscriptionformGroup" (ngSubmit)="subscriptionformSubmit()">
              <mat-card style="width: 450px;">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image">
                  <div  [innerHTML]="subscriptionImage | sanitizeHtml"></div>
                </div>
                <mat-card-title>Subscription</mat-card-title>
                <mat-card-subtitle>Microsoft.Resources/subscriptions</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content  style="padding: 10px 20px;" class="row">
                <div *ngFor="let field of subscriptionUserData.resourceData.resourcePropertyList">
                  <div *ngIf="field.propertyNameAlias">
                    <div *ngIf="field.itemList">
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{field.propertyNameAlias}}</mat-label>
                        <mat-select [formControlName]="field.propertyName" required>
                          <mat-option *ngFor="let option of field.itemList.split(';')" [value]="option">
                            {{ option }}
                          </mat-option>
                        </mat-select>
                        <mat-error>This is a mandatory Field</mat-error>
                      </mat-form-field>
                    </div>
                    <div *ngIf="!field.itemList">
                      <div
                        *ngIf="field.propertyName==='subscriptionIdentifier' || field.propertyName==='Business_Justification'; else nonsubscriptionblock">
                        <mat-form-field *ngIf="field.propertyName==='subscriptionIdentifier'" appearance="outline" style="width: 100%;">
                          <mat-label>{{field.propertyNameAlias}}</mat-label>
                          <input matInput type="text" [value]="field.propertyValue" [formControlName]="field.propertyName"
                            required="" #input [maxlength]=10  (keypress)="omit_special_char($event)">
                            <mat-hint style="color: red;" *ngIf="subName">Subscription Name Already Exist</mat-hint>
                          <mat-hint style="color: aliceblue;" align="end">{{input.value.length}}/10</mat-hint>
                        </mat-form-field>
                        <mat-form-field *ngIf="field.propertyName==='Business_Justification'" appearance="outline" style="width: 100%;">
                          <mat-label>{{field.propertyNameAlias}}</mat-label>
                          <textarea style="resize: none;" matInput type="text" [value]="field.propertyValue"
                            [formControlName]="field.propertyName" required="" #input></textarea>
                        </mat-form-field>
                      </div>
                      <ng-template #nonsubscriptionblock>
                        <mat-form-field appearance="outline" style="width: 100%;">
                          <mat-label>{{field.propertyNameAlias}}</mat-label>
                          <input matInput type="text" [value]="field.propertyValue" [formControlName]="field.propertyName">
                          <mat-error>This is a mandatory Field</mat-error>
                        </mat-form-field>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>Draft Name</mat-label>
                  <input matInput [formControl]="newdraftControl" required maxlength="50" appFormInput>
                </mat-form-field>

              </mat-card-content>
              <mat-card-actions align="end">
                <button mat-button type="button" (click)="goBack()">Back</button>
                <button mat-flat-button type="submit" color="accent"
                [disabled]="subscriptionformGroup.invalid || newdraftControl.invalid">Create</button>
              </mat-card-actions>
            </mat-card>
            </form>
          </section>
          <footer class="unimatrix-footer"></footer>
    </main>
</app-navigation>