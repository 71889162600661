import { Component } from '@angular/core';
import { AesencryptionService } from 'src/services/encryption.service';
import { RoleData } from '../../pages/roles-table/roles';
import { SessionService } from 'src/services/session.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  userRoles: RoleData;

  constructor(
    private sessionService: SessionService,
    private authService: MsalService,
    private aesencryptionService: AesencryptionService,
  ){
    this.userRoles = JSON.parse(aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  logout() {
    this.sessionService.clearCookieAndSessionStorage();
    this.authService.logoutRedirect();
  }

}
