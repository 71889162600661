<h1 mat-dialog-title class="dialog-title">Actions available for resources</h1>
<div mat-dialog-content class="dialog-content">
    <table border="1">
        <thead>
            <tr>
                <th>Resource Name</th>
                <th>Create</th>
                <th>Update</th>
                <th>Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Subscription</td>
                <td>Y</td>
                <td>NA</td>
                <td>Y</td>
            <tr>
                <td>Windows VM</td>
                <td>Y</td>
                <td>Y</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>Ubuntu VM</td>
                <td>Y</td>
                <td>Y</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>RHEL VM</td>
                <td>Y</td>
                <td>Y</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>Postgres</td>
                <td>Y</td>
                <td>Y</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>SQL</td>
                <td>Y</td>
                <td>NA</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>MySql</td>
                <td>Y</td>
                <td>Y</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>Mongo DB</td>
                <td>Y</td>
                <td>Y</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>Cassandra DB</td>
                <td>Y</td>
                <td>NA</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>Redis Cache</td>
                <td>Y</td>
                <td>Y</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>Storage account</td>
                <td>Y</td>
                <td>NA</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>Static Webapp</td>
                <td>Y</td>
                <td>Y</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>AKS</td>
                <td>Y</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Service Bus</td>
                <td>Y</td>
                <td>NA</td>
                <td>Y</td>
            </tr>
            <tr>
                <td>Ubuntu VMSS</td>
                <td>Y</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Windows VMSS</td>
                <td>Y</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
        </tbody>
    </table>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
</div>