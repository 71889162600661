import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AesencryptionService } from 'src/services/encryption.service';
import { RoleData } from '../roles-table/roles';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  animations: [
    trigger('enterXAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(0)', opacity: 0 }),
        animate('2.5s ease-in-out', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('enterYAnimation', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      state('out', style({ transform: 'translateY(30%)', opacity: 0 })),
      transition('out => in', [
        style({ transform: 'translateY(30%)', opacity: 0 }),
        animate('1s ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('fadeInAnimation', [
      state('fadeIn', style({ transform: 'translate(0)', opacity: 1 })),
      state('fadeOut', style({ transform: 'translate(0)', opacity: 0 })),
      transition('fadeOut => fadeIn', [
        style({ transform: 'translate(0)', opacity: 0 }),
        animate('1s ease-in-out', style({ transform: 'translate(0)', opacity: 1 })),
      ]),
    ]),
  ]
})
export class LandingPageComponent implements AfterViewInit {
  userRoles: RoleData;
  isDashboardSectionInView = false;
  isMonitoringSectionInView = false;
  isBlueprintsSectionInView = false;
  
  @ViewChild('landingPageDashboards', { static: false }) landingPageDashboards!: ElementRef;
  @ViewChild('landingPageMonitoring', { static: false }) landingPageMonitoring!: ElementRef;
  @ViewChild('landingPageBlueprints', { static: false }) landingPageBlueprints!: ElementRef;

  constructor(
    private aesencryptionService: AesencryptionService,
    private router: Router,
  ) {
    this.userRoles = JSON.parse(aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  ngAfterViewInit() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target === this.landingPageDashboards.nativeElement) {
            this.isDashboardSectionInView = true;
          } else if (entry.target === this.landingPageMonitoring.nativeElement) {
            this.isMonitoringSectionInView = true;
          } else if (entry.target === this.landingPageBlueprints.nativeElement) {
            this.isBlueprintsSectionInView = true;
          }
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.1 });

    observer.observe(this.landingPageDashboards.nativeElement);
    observer.observe(this.landingPageMonitoring.nativeElement);
    observer.observe(this.landingPageBlueprints.nativeElement);
  }

  handleButtonClick() {
    if (this.userRoles.blueprintView) {
      this.router.navigate(['/blueprint/explorer']);
    } else {
      window.location.href = 'mailto:ecad-support@devopscircuit.com';
    }
  }

}