//Angular Packages
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxChartsModule } from '@swimlane/ngx-charts';

//To Import new component into app-module use ng g c {newcomponentName} --module=app.module
import { AppComponent } from './app.component';
import { LoginComponent } from './components/pages/login/login.component';
import { MonitoringComponent } from './components/pages/monitoring/monitoring.component'
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { InfraCostDialogComponent } from './components/dialog/infra-cost-dialog/infra-cost-dialog.component';
import { FormBuilderFieldDialogComponent } from './components/dialog/formbuilder-field-dialog/formbuilder-field-dialog.component';
import { ResourceTypeDialogComponent } from './components/dialog/formbuilder-resource-type-dialog/formbuilder-resource-type-dialog.component';
import { PublishFormComponent } from './components/pages/admin/admin.component';
import { CallbackComponent } from './components/pages/callback/callback.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import { NavigationService } from 'src/services/navigation.service';
import { BlueprintComponent } from './components/pages/blueprint/blueprint.component';
import { NavigationComponent } from './components/shared/navigation/navigation.component';

//Icon Pack
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

//Import All Material modules
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop'
//MSAL Library
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { DndModule } from 'ngx-drag-drop';
import { BlueprintCanvasComponent } from './components/pages/blueprint/blueprint-canvas/blueprint-canvas.component';
import { BlueprintNewSubscriptionComponent } from './components/pages/blueprint/blueprint-new-subscription/blueprint-new-subscription.component';
import { FileExplorerComponent } from './components/pages/blueprint/blueprint-explorer/file-explorer.component';
import { RolesTableComponent } from './components/pages/roles-table/roles-table.component';
import { BlueprintResourceFormDialogComponent } from './components/dialog/blueprint-resource-form-dialog/blueprint-resource-form-dialog.component';
import { AppfamilyComponent } from './components/dialog/appfamily/appfamily.component';
import { ResourceFormsComponent } from './components/pages/formbuilder/resource-forms/resource-forms.component';
import { FormbuilderComponent } from './components/pages/formbuilder/formbuilder.component';
import { EpochToDatePipe } from './pipes/epoch-to-date.pipe';
import { BlueprintCloneDialogComponent } from './components/dialog/blueprint-clone-dialog/blueprint-clone-dialog.component';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { ApprovalComponent } from './components/pages/approval/approval.component';
import { ApprovalCommentDialogComponent } from './components/dialog/approval-comment-dialog/approval-comment-dialog.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { FormInputDirective } from './directives/form-input.directive';
import { DraftTemplateActivationDialogComponent } from './components/dialog/draft-template-activation-dialog/draft-template-activation-dialog.component';
import { ApprovalReviewComponent } from './components/pages/approval/approval-review/approval-review.component';
import { ServiceAccountComponent } from './components/pages/service-account/service-account.component';
import { DeploymentComponent } from './components/pages/deployment/deployment.component';
import { EpochToHumanReadablePipe } from './pipes/epoch-to-human-readable.pipe';
import { AddTenantDialogComponent } from './components/dialog/add-tenant-dialog/add-tenant-dialog.component';
import { TenantsCatalogueComponent } from './components/pages/tenants-catalogue/tenants-catalogue.component';
import { LandingPageComponent } from './components/pages/landing-page/landing-page.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LatestDatePipe } from './pipes/latest-date.pipe';
import { BlueprintResourceActionsDialogComponent } from './components/dialog/blueprint-resource-actions-dialog/blueprint-resource-actions-dialog.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback() {
  // This is intentional
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      navigateToLoginRequestUrl: false,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MonitoringComponent,
    DashboardComponent,
    NavigationComponent,
    FormBuilderFieldDialogComponent,
    ResourceTypeDialogComponent,
    PublishFormComponent,
    InfraCostDialogComponent,
    CallbackComponent,
    HeaderComponent,
    ConfirmDialogComponent,
    BlueprintComponent,
    BlueprintCanvasComponent,
    BlueprintNewSubscriptionComponent,
    FileExplorerComponent,
    RolesTableComponent,
    BlueprintResourceFormDialogComponent,
    AppfamilyComponent,
    ResourceFormsComponent,
    FormbuilderComponent,
    EpochToDatePipe,
    BlueprintCloneDialogComponent,
    ApprovalComponent,
    ApprovalCommentDialogComponent,
    SanitizeHtmlPipe,
    FormInputDirective,
    DraftTemplateActivationDialogComponent,
    ApprovalReviewComponent,
    ServiceAccountComponent,
    DeploymentComponent,
    EpochToHumanReadablePipe,
    AddTenantDialogComponent,
    TenantsCatalogueComponent,
    LandingPageComponent,
    FooterComponent,
    LatestDatePipe,
    BlueprintResourceActionsDialogComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    MsalModule,
    MaterialModule,
    DragDropModule,
    TablerIconsModule.pick(TablerIcons),
    TourMatMenuModule,
    DndModule, 
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxChartsModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptor, 
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: ErrorInterceptor, 
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NavigationService,
    CookieService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }