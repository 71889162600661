import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersubscriptionService {
  GET_SUBSCRIPTIONS_URL = environment.API_URL + 'GetUserSubscriptions'; //Get List of Subscriptions
  GET_FQ_SUBSCRIPTION_BLUEPRINT_URL = environment.API_URL + 'FQSubscriptionDetails'; //Get Empty subscription details
  GET_REMOTE_SUBSCRIPTIONS_TEMPLATES_URL=environment.API_URL+'BluePrintTemplatesList';//Get Remote Template Subscriptions
  constructor(private http: HttpClient) { }

  getSubscriptionList(value: boolean) {
    const params = new HttpParams()
    .set('refreshSubscription', value)
    return this.http.get(this.GET_SUBSCRIPTIONS_URL, {params, observe: 'response' });
  }

  public getFQSubscriptionBlueprint(dguid:string) {
    const params = new HttpParams()
    .set('dguid', dguid)
    return this.http.get(this.GET_FQ_SUBSCRIPTION_BLUEPRINT_URL, {params, observe: 'response'});
  }
  getRemoteSubscriptionTemplateList(){
    return this.http.get(this.GET_REMOTE_SUBSCRIPTIONS_TEMPLATES_URL, { observe: 'response' });
  }
}