import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as d3 from 'd3';
import { OrgChart } from "d3-org-chart";
import jsPDF from 'jspdf';
import { Location } from '@angular/common';
import { InfraCostDialogComponent } from 'src/app/components/dialog/infra-cost-dialog/infra-cost-dialog.component';
import { CoreService } from 'src/app/components/shared/snackbar/snackbar.service';
import { BlueprintDataService } from 'src/services/blueprint-data.service';
import { DialogService } from 'src/services/confirm-dialog.service';
import { Base64Service } from 'src/services/base64.service';
import { DraftTemplateActivationDialogComponent } from 'src/app/components/dialog/draft-template-activation-dialog/draft-template-activation-dialog.component';
import { RoleData } from '../../roles-table/roles';
import { AesencryptionService } from 'src/services/encryption.service';
import { HttpResponse } from '@angular/common/http';
import { BlueprintExportService } from 'src/services/blueprint-export.service';
import { ResourceNameService } from 'src/services/resource-name.service';
import { ResourceNameOptions } from 'src/app/models/IResourceNameOptions';
import { BlueprintCanvasService } from 'src/services/blueprint-canvas.service';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { BlueprintsService } from '../blueprints.service';

@Component({
  selector: 'app-blueprint-canvas',
  templateUrl: './blueprint-canvas.component.html',
  styleUrls: ['./blueprint-canvas.component.scss'],
})
export class BlueprintCanvasComponent implements OnInit,OnChanges,AfterViewInit{
  @ViewChild('chartContainer') chartContainer!: ElementRef;
  @Input() toolbar: any;
  chart: any;
  d3Json: any;
  height!: number;
  width!: number;

  isChecked = true;

  @Output() shareResourceDataEvent = new EventEmitter();
  formattedNum: any;
  bpJsonCopy: any;
  userRoles: RoleData;
  shareToRoleIds: any;
  isTemplateActive:boolean=true;
  isTemplate: any;
  resourceNameFormula: any;
  
  constructor(
    private _blueprintDataService: BlueprintDataService, 
    private _blueprintCanvasService: BlueprintCanvasService,
    private _coreService: CoreService, 
    private dialogService: DialogService,
    private _blueprintService: BlueprintsService,
    public _dialog: MatDialog,
    private base64Service: Base64Service,
    private elRef: ElementRef,
    private aesencryptionService: AesencryptionService,
    private location: Location,
    private resourceNameService: ResourceNameService,
    private blueprintExportService: BlueprintExportService,
    private router: Router
  ) { 
    this.userRoles = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')));
  }

  ngOnInit() {
    this.d3Json = this._blueprintDataService.getBlueprintData();
    this.d3Json.subscribe((data: any) => {
      this.bpJsonCopy = data;
      this.bpConvert(data);
      this.isTemplate=this.bpJsonCopy.subscriptionData.resourceData.resourceStatus!=='fullyQualified';
    })
  }

  updateSize() {
    this.height = this.elRef.nativeElement.parentNode.offsetHeight;
    this.width = this.elRef.nativeElement.parentNode.offsetWidth;
  }

  ngAfterViewInit() {
    this.updateSize();
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }

  expandAll() {
    this.chart.expandAll().fit()
  }

  collapseAll() {
    this.chart.collapseAll().fit()
  }

  zoomIn() {
    this.chart.zoomIn()
  }

  zoomOut() {
    this.chart.zoomOut()
  }

  getDate() {
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}_${date.getHours()}${date.getMinutes()}`;
    return formattedDate;
  }

  exportImg() {
    setTimeout(() => {
      this.chart.exportImg()
    }, 1000);
  }

  exportMySvg() {
    this.blueprintExportService.exportSvg('chartContainer', `${this.bpJsonCopy.userRequestInfo.draftName}_${this.getDate()}`);
  }
  
  downloadPdf() {
    const pdfName = `${this.bpJsonCopy.userRequestInfo.draftName}_${this.getDate()}.pdf`;
    setTimeout(() => {
      this.chart.exportImg({
        save: false,
        onLoad: (base64: string) => {
          const pdf = new jsPDF('landscape');
          const img = new Image();
          img.src = base64;
          img.onload = function () {
            const imgWidth = img.width;
            const imgHeight = img.height;
            const pageWidth = pdf.internal.pageSize.getWidth();
            const ratio = pageWidth / imgWidth;
            const scaledHeight = imgHeight * ratio;
            pdf.addImage(img, 'JPEG', 0,0,pageWidth, scaledHeight);
            pdf.save(pdfName);
          };
        },
      });
    }, 1000);
  }

  compact() {
    if (this.isChecked === true) {
      this.chart.compact(true).render()
      this._coreService.openSnackBar('Switched to Compact View', 1000);
    }
    else {
      this.chart.compact(false).render()
      this._coreService.openSnackBar('Switched to Flat View', 1000);
    }
  }

  highlightNode(uguid: string) {
    this.chart.setCentered(uguid).render();
    this.chart.setHighlighted(uguid).render()
    setTimeout(() => {
      this.chart.clearHighlighting();
    }, 3000)
  }

  filterChart(filterEvent: any) {
    // Check if event is null
    if (!filterEvent) return;

    // Get input value
    const value = filterEvent.srcElement?.value;

    // Check if chart is null
    if (!this.chart) return;

    // Clear previous higlighting
    this.chart.clearHighlighting();

    // Get chart nodes
    const data = this.chart.data();

    // Check if data is null
    if (!data) return;

    // Mark all previously expanded nodes for collapse
    data.forEach((d: any) => (d._expanded = false));

    // Loop over data and check if input value matches any name
    data.forEach((d: any) => {
      if (value != '' && d.name?.toLowerCase().includes(value.toLowerCase())) {
        // If matches, mark node as highlighted
        d._highlighted = true;
        d._expanded = true;
      }
    });

    // Update data and rerender graph
    this.chart.data(data).render().fit();
    // this.chart.setUpToTheRootHighlighted(data).render().fit()
  }

  bpConvert(blueprintJson: any) {
    this.d3Json = [];
    const resourceData = blueprintJson?.subscriptionData?.resourceData;
    const userRequestInfo = blueprintJson?.userRequestInfo;
    const resourcePropertyList = resourceData?.resourcePropertyList || [];
    const subscriptionName = this.getPropertyValue(resourcePropertyList, "subscriptionFullyQualifiedName");
    const applicationFamily = this.getPropertyValue(resourcePropertyList, "applicationFamily");
    const subscriptionNameString = subscriptionName?.split('-') || [];
    const [appFam, env, subscriptionIdentifier] = this.resourceNameService.getLastElements(subscriptionNameString, 3);
    let totalSubscriptionCost = resourceData?.currentCost || 0;

    blueprintJson?.resourceInventoryData?.forEach((blueprintResource: any) => {
      const resourceType = blueprintResource?.resourceType || "";
      const resourceGeneralDisplayName = blueprintResource?.resourceGeneralDisplayName || "";
      const resourceTypeImage = blueprintResource?.resourceGeneralIconUrl || "";
      let resourceTypeCost = 0;
      const resourceMap: any = {};
      const resourceTypeList: any[] = [];
      const resourceTypeParentNodeMapObj: any = {};
      const countObj: any = {};

      blueprintResource?.resourceList?.forEach((resourceObj: any) => {
        const { identifier, currentCost, resourceDisplayName, azid, resourceNameFormula} = this.getResourceDetails(resourceObj);
        totalSubscriptionCost += currentCost || 0;
        resourceTypeCost += currentCost || 0;
        countObj[resourceDisplayName] = (countObj[resourceDisplayName] || 0) + (azid ? 0 : 1);
        const options: ResourceNameOptions = {
          count: (resourceObj.resourceData.indexNo && resourceObj.resourceData.indexNo !== "") ? resourceObj.resourceData.indexNo : countObj[resourceDisplayName],
          subscriptionIdentifier,
          env,
          identifier,
          appFam,
          applicationFamily,
          subscriptionName
        };
        const resourceName = this.resourceNameService.getResourceName(resourceNameFormula || resourceDisplayName, options);
        const azResourceId = this.getResourceId(azid);
        this.addSubResTypeNode(resourceMap, resourceTypeParentNodeMapObj, resourceObj, resourceName, resourceType,userRequestInfo);
        this.addResourceNode(resourceTypeList, resourceObj, azResourceId, resourceName, resourceType,userRequestInfo);
      });

      this.addResourceTypeNode(resourceType, resourceTypeCost, resourceData?.uguid, resourceGeneralDisplayName, resourceTypeImage,userRequestInfo);
      this.updateD3JsonWithResourceMap(resourceMap, resourceTypeParentNodeMapObj, resourceTypeList);
    });

    this.addSubscriptionNode(resourceData, totalSubscriptionCost,userRequestInfo);
    this.updateChart();
  }

  getPropertyValue(resourcePropertyList: any[], propertyName: string) {
    const propertyObj = resourcePropertyList.find((rcs: any) => rcs?.propertyName === propertyName);
    return propertyObj ? propertyObj.propertyValue : '';
  }

  getResourceDetails(resourceObj: any) {
    const resourceData = resourceObj?.resourceData || {};
    this.resourceNameFormula=resourceData?.resourceNameFormula || ''
    return {
      identifier: (resourceData?.ticketId || '').replace(/^EAA-(\w+)/, '$1'),
      currentCost: resourceData?.currentCost || 0,
      resourceDisplayName: resourceData?.resourceDisplayName || '',
      azid: resourceData?.azid || '',
      resourceIconUrl: resourceData?.resourceIconUrl || '',
      resourceNameFormula: resourceData?.resourceNameFormula || '',
      uguid: resourceData?.uguid || '',
      managedBy: resourceData?.managedBy || '',
      ticketId: resourceData?.ticketId || ''
    };
  }

  getResourceId(azid: string) {
    const splitString = azid.split('/');
    return splitString[splitString.length - 1];
  }

  addSubResTypeNode(resourceMap: any, resourceTypeParentNodeMapObj: any, resourceObj: any, resourceName: string, resourceType: string,userRequestInfo:any) {
    const { currentCost, azid } = this.getResourceDetails(resourceObj);
    const azResourceId = this.getResourceId(azid);
    const resourceDisplayName = resourceObj?.resourceData?.resourceDisplayName;
    if (!(resourceDisplayName in resourceMap)) {
      resourceMap[resourceDisplayName] = [];
      resourceTypeParentNodeMapObj[resourceDisplayName] = this.addSubResourceTypeNode(resourceObj, resourceDisplayName, resourceType,userRequestInfo);
    }

    let currentcost = resourceTypeParentNodeMapObj[resourceDisplayName]?.cost;
    currentcost += currentCost;
    if (resourceTypeParentNodeMapObj[resourceDisplayName]) {
      resourceTypeParentNodeMapObj[resourceDisplayName].cost = currentcost;
    }

    if (resourceMap[resourceDisplayName]) {
      resourceMap[resourceDisplayName].push(this.addSubResourceNode(resourceObj, azResourceId, resourceName, resourceDisplayName, resourceType,userRequestInfo));
    }
  }

  addSubResourceTypeNode(resourceObj: any, resourceDisplayName: string, resourceType: string,userRequestInfo:any) {
    return {
      "id": resourceDisplayName,
      "cost": 0,
      "name": resourceDisplayName,
      "type": resourceDisplayName,
      "parentId": resourceType,
      "nodeType": "subResourceType",
      "managedBy": "managed",
      "ticketId": "",
      "image": this.base64Service.decode(resourceObj?.resourceData?.resourceIconUrl),
      "state": userRequestInfo?.state,
      //"status":"Update"
    };
  }

  addSubResourceNode(resourceObj: any, azResourceId: string, resourceName: string, resourceDisplayName: string, resourceType: string,userRequestInfo:any) {
    return {
      "id": resourceObj?.resourceData?.uguid,
      "cost": resourceObj?.resourceData?.currentCost || 0,
      "parentId": resourceDisplayName,
      "nodeType": "subResource",
      "name": azResourceId || resourceName,
      "ticketId": resourceObj?.resourceData?.ticketId || "",
      "type": resourceType,
      "managedBy": resourceObj?.resourceData?.managedBy,
      "image": this.base64Service.decode(resourceObj?.resourceData?.resourceIconUrl),
      "status": resourceObj?.resourceData?.userActionType,
      "resourceStatus": resourceObj?.resourceData?.resourceStatus,
      "state": userRequestInfo?.state,
    };
  }

  addResourceNode(resourceTypeList: any[], resourceObj: any, azResourceId: string, resourceName: string, resourceType: string,userRequestInfo:any) {
    if (resourceTypeList) {
      resourceTypeList.push({
        "id": resourceObj?.resourceData?.uguid,
        "cost": resourceObj?.resourceData?.currentCost || 0,
        "name": azResourceId || resourceName,
        "nodeType": "resource",
        "ticketId": resourceObj?.resourceData?.ticketId || "",
        "type": resourceType,
        "managedBy": resourceObj?.resourceData?.managedBy,
        "parentId": resourceType,
        "image": this.base64Service.decode(resourceObj?.resourceData?.resourceIconUrl),
        "status": resourceObj?.resourceData?.userActionType,
        "resourceStatus": resourceObj?.resourceData?.resourceStatus,
        "state": userRequestInfo?.state,
      });
    }
  }

  addResourceTypeNode(id: string, cost: number, parentId: string, name: string, image: string,userRequestInfo:any) {
    if (this.d3Json) {
      this.d3Json.push({
        "id": id,
        "cost": cost || 0,
        "parentId": parentId,
        "name": name,
        "managedBy": "managed",
        "nodeType": "resourceType",
        "ticketId": "",
        "type": id,
        "image": this.base64Service.decode(image),
        "state": userRequestInfo?.state,
        // "resourceStatus": resourceObj?.resourceData?.resourceStatus
      });
    }
  }

  updateD3JsonWithResourceMap(resourceMap: any, resourceTypeParentNodeMapObj: any, resourceTypeList: any[]) {
    if (Object.keys(resourceMap)?.length > 1) {
      if (this.d3Json) {
        this.d3Json.push(...Object.values(resourceTypeParentNodeMapObj));
        Object.values(resourceMap).forEach((resourcelist: any) => this.d3Json = [...this.d3Json, ...resourcelist])
      }
    }
    else if (this.d3Json) {
        this.d3Json.push(...resourceTypeList);
      }
  }

  addSubscriptionNode(resourceData: any, totalSubscriptionCost: number,userRequestInfo:any) {
    if (resourceData) {
      this.d3Json.push({
        "id": resourceData?.uguid,
        "cost": totalSubscriptionCost || 0,
        "parentId": "",
        "managedBy": "managed",
        "nodeType": "subscription",
        "ticketId": resourceData?.ticketId || "",
        "name": this.getPropertyValue(resourceData?.resourcePropertyList, "subscriptionFullyQualifiedName"),
        "type": resourceData?.resourceGeneralDisplayName || "",
        "image": resourceData?.resourceIconUrl ? this.base64Service.decode(resourceData?.resourceIconUrl) : "",
        "status": resourceData?.userActionType,
        "resourceStatus":resourceData?.resourceStatus,
        "state": userRequestInfo?.state,
      });
    }
  }

  updateChart() {
    if (!this.d3Json || !this.chart || !this.chartContainer?.nativeElement) {
      return
    }
    this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.d3Json)
      .svgWidth(this.width)
      .svgHeight(this.height)
      .onNodeClick((d: any) => this.shareResourceDataEvent.emit(d.data))
      .nodeUpdate(() => { d3.select('.node-rect').attr('stroke', 'none'); })
      .linkUpdate(() => {
        d3.selectAll('.link').attr('stroke', (d: any) =>
          d.data._upToTheRootHighlighted ? '#77FF92' : '#E4E2E9'
        ).attr('stroke-width', (d: any) =>
          d.data._upToTheRootHighlighted ? 4 : 1
        )
      })
      .nodeWidth(() => 200)
      .nodeHeight(() => 90 + 25)
      .childrenMargin(() => 150)
      .compactMarginBetween(() => 35)
      .compactMarginPair(() => 100)
      .siblingsMargin(() => 100)
      .defaultFont('Inter')
      .render();
      this.updateNodeContent();
    this.chart.expandAll().fit()
  }
  
  updateNodeContent(){
    this.chart.nodeContent(function (d: any) {
        if (!d?.data) {
          return '';
        }
        const imageDiffVert = 20;
        return `
        <div style='width:${d.width}px;height:${d.height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px;'>
          <div title="${d.data.name}" style="font-family: 'Inter', sans-serif;background-color:${d.data.resourceStatus === 'deleted' ?  '#f443364c' : d.data.managedBy === "managed" ? '#FFFFFF' : '#f4f4f4'}; margin-left:-1px;width:${d.width + 20}px;height:${d.height - imageDiffVert}px;border-radius:10px;border: ${d.data._highlighted || d.data._upToTheRootHighlighted ? '4px solid #77FF92"' : '1px solid #E4E2E9"'} >
            <div style="display:flex;justify-content:flex-end;margin-top:5px;margin-right:8px; font-size:10px; font-weight: 500; color:#08011e;">
              ${d.data.ticketId}
            </div>
            <div style="margin-top:${-imageDiffVert - 20}px;margin-left:${15}px;width:40px; object-fit: contain;height:40px; filter:${d.data.managedBy === "managed" ? 'saturate(110%);' : 'saturate(10%)'} ">   
              ${d.data.image}
            </div>
            <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size:14px;color:#08011E;margin-left:20px;margin-top:12px;overflow-y:hidden">  
              ${d.data.name} 
            </div>
            <div style="white-space: normal;color:#716E7B;margin-left:20px;margin-top:5px;font-size:8px;"> 
              ${d.data.type} 
            </div>
            <div style="display:flex;justify-content:flex-end;margin-top:15px;margin-right:8px; font-size:8px; color:#716E7B;">
              € ${Math.floor(d.data.cost)}
            </div>
            ${d.data.status ? 
              `<div style="display: flex;justify-content: flex-start;margin-left: 20px;margin-top: -10px;font-size: 10px; color:black">
                <div style="display: flex; align-items: center; margin-right: 5px;">
                  <div style="width: 10px; height: 10px; border-radius: 50%; background-color: currentColor; color: ${d.data.status === "create" ? '#00c853' : d.data.status === "delete" ? '#f44336' : d.data.status === "new" ? 'grey' : '#0497f8'};" >
                  </div>
                  </div>
                    ${d.data.state === 'Deployment - Completed' ? `${d.data.status}d` : `To be ${d.data.status}d`}
                  </div>
                </div>
              </div>` 
                : 
              '<div></div>'
            }
          </div>
        </div>
        `;
      })
  }

  bpCostSubmit() {
    if (!this.bpJsonCopy.userRequestInfo?.dguid) {
      return;
    }
    this._blueprintService.postBPCostCalculate(this.bpJsonCopy.userRequestInfo.dguid).subscribe({
      next: (response) => {
        const isTemplateDraft=this.bpJsonCopy.userRequestInfo.state==='template';
        const data = {
          dguid: this.bpJsonCopy.userRequestInfo.dguid,
          isTemplateDraft, // Pass the isTemplateDraft flag as part of the data object
        };
        if (!this._dialog) {
          return;
        }
        const dialogRef = this._dialog.open(InfraCostDialogComponent, {
          disableClose: true,
          data,
        });
        if (dialogRef) {
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              //Comment
            }
          });
        }
      },
      error: (error) => {
          this._coreService.openSnackBar('Error while calculating cost', 1000);
          console.error('Error while calculating cost: ', error);
      }
    });
  }

  exit() {
    this.router.navigate(['blueprint/explorer']);
    this._blueprintDataService.setDguid(this.bpJsonCopy.userRequestInfo.dguid);
  }

  shareTo() {
    this._blueprintService.getActivationStatus(this.bpJsonCopy.userRequestInfo.dguid).pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error getting Activation Status', 2000, 'warn-snackbar');
        console.error('Error getting Activation Status: ', error);
        return throwError(() => error);
      })
    ).subscribe((response: any) => {
      this.shareToRoleIds = response.body.ShareToRoles;
      this.isTemplateActive = response.body.IsActive;
      const data = {
        shareToRoleIds: this.shareToRoleIds,
        isTemplateActive: this.isTemplateActive
      };
      const dialogRef = this._dialog.open(DraftTemplateActivationDialogComponent, {
        data,
        width: '400px',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.postActivationStatus(result.sharedUserRolesId, result.isActive);
        }
      });
    }
    );
  }
  postActivationStatus(RoleIds:any,activationStatus:boolean){
    this._blueprintService.postActivationStatus(this.bpJsonCopy.userRequestInfo.dguid,activationStatus,RoleIds).pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error Sharing the Template', 2000,'warn-snackbar');
        const err = ': Failed Sharing the Template';
        return throwError(() => err);
      })
    ).subscribe(
      (response: HttpResponse<any>) => {
        const message = response.body;
        this._coreService.openSnackBar(message, 5000,'success-snackbar');
      }
  );
  }

  openRetrigger(){
    this._blueprintDataService.retriggerDrawerToggle();
  }
}
