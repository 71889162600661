import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  GET_JWT_TOKEN_URL = environment.API_URL + 'JwtToken';
  GET_USER_DETAILS_URL = environment.API_URL + 'Login';
  // EXCLUDED_API_URL = environment.API_URL + 'ExcludedApi'; Add the URL you want to exclude
  GET_GRAFANA_TOKEN_URL = environment.UNIMATRIX_URL + 'Grafana';
  GRAFANA_DOMAIN_URL = environment.GRAFANA_DOMAIN_URL;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the request URL is the excluded one, don't intercept it
    if (req.url === this.GET_GRAFANA_TOKEN_URL || req.url === this.GRAFANA_DOMAIN_URL) {
      return next.handle(req);
    }

    let clonedReq = req;

    if (req.method === 'POST' || req.method === 'PUT' || req.method === 'DELETE') {
      clonedReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json'),
        withCredentials: true,
      });
    } else if (req.method === 'GET') {
      clonedReq = req.clone({
        withCredentials: true,
      });
    } else {
      clonedReq = req.clone({
        withCredentials: true,
      });
    }

    if (req.url.endsWith('.svg')) {
      return next.handle(req);
    }

    return next.handle(clonedReq);
  }
}