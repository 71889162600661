import { Injectable } from '@angular/core';
import { ResourceNameOptions } from 'src/app/models/IResourceNameOptions';

@Injectable({
  providedIn: 'root'
})

export class ResourceNameService {

  getResourceName(resourceName: string, options: ResourceNameOptions) {
    const { count, subscriptionIdentifier, env, identifier, appFam, applicationFamily, subscriptionName } = options;
    const formattedCount = +count < 10 && !count.toString().startsWith('0') ? '0' + count : count.toString();
    return resourceName.replace(/{indexNo}/, formattedCount)
      .replace(/{subscriptionIdentifier}/, subscriptionIdentifier)
      .replace(/{env}/, env)
      .replace(/{identifier}/, identifier)
      .replace(/{appFam}/, appFam)
      .replace(/{applicationFamily}/, applicationFamily)
      .replace(/{subscriptionName}/, subscriptionName);
  }

  getLastElements(array: [], count: number) {
    return array.slice(Math.max(array.length - count, 0));
  }
}