import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ResourceNameService } from './resource-name.service';
import { ResourceNameOptions } from 'src/app/models/IResourceNameOptions';

@Injectable({
  providedIn: 'root',
})
export class ResourceDataService {
  subscriptionName: any;
  blueprintJson: any;
  createdTableDataSource = new MatTableDataSource<any>();
  updatedTableDataSource = new MatTableDataSource<any>();
  deletedTableDataSource = new MatTableDataSource<any>();
  syncedTableDataSource = new MatTableDataSource<any>();
  countObj: any = {};

  constructor(private resourceNameService: ResourceNameService) { }
  bpReviewData(blueprintJson: any) {
    this.blueprintJson = blueprintJson;
    const created: any[] = [];
    const updated: any[] = [];
    const deleted: any[] = [];
    const synced: any[] = [];
    for (const resourceType of blueprintJson.resourceInventoryData) {
      for (const resourceList of resourceType.resourceList) {
        const resourceName = this.getResourceDetails(resourceList.resourceData);
        resourceList.resourceData.resourceName = resourceName;
        this.categorizeResource(resourceList.resourceData, created, updated, deleted, synced);
      }
    }

    this.addTotalCosts(created);
    this.addTotalCosts(updated);
    this.addTotalCosts(deleted);
    this.addTotalCosts(synced);
    return { created, updated, deleted, synced };
  }

  private categorizeResource(resourceData: any, created: any[], updated: any[], deleted: any[], synced: any[]) {
    if (resourceData.userActionType === 'create') {
      created.push(resourceData);
    } else if (resourceData.userActionType === 'update') {
      updated.push(resourceData);
    } else if (resourceData.userActionType === 'delete') {
      deleted.push(resourceData);
    } else if (resourceData.userActionType === '' && resourceData.resourceStatus !== "deleted" && resourceData.isChanged === true) {
      synced.push(resourceData);
    }
  }

  private addTotalCosts(resources: any[]) {
    if (resources.length > 0) {
      const costs = this.calculateTotalCosts(resources);
      if (costs[0] - costs[1] !== 0) {
        resources.push({
          previousTotalCost: costs[0],
          currentTotalCost: costs[1]
        });
      }
    }
  }

  calculateTotalCosts(data:any) {
    let totalPreviousCost = 0;
    let totalCurrentCost = 0;
    for(const resource of data){
      totalPreviousCost += resource.previousCost;
      totalCurrentCost += resource.currentCost;
    }
    return [totalPreviousCost, totalCurrentCost];
  }

  getResourceDetails(resourceData: any) {
    const resourceDisplayName = resourceData?.resourceDisplayName || '';
    const azid = resourceData?.azid;
    if (azid && azid !== "") {
      return azid.split('/').pop();
    }
    this.countObj[resourceDisplayName] = (this.countObj[resourceDisplayName] || 0) + (azid ? 0 : 1);
    this.subscriptionName = this.getPropertyValue(this.blueprintJson.subscriptionData.resourceData.resourcePropertyList,'subscriptionFullyQualifiedName');
    const applicationFamily = this.getPropertyValue(this.blueprintJson.subscriptionData.resourceData.resourcePropertyList,'applicationFamily');
    const subscriptionNameString = this.subscriptionName?.split('-') || [];
    const [appFam, env, subscriptionIdentifier] = this.getLastElements(subscriptionNameString,3);
    const identifier = (resourceData?.ticketId || '').replace(/^EAA-(\w+)/,'$1');
    const options: ResourceNameOptions = {
      count: (resourceData.indexNo && resourceData.indexNo !== "") ? resourceData.indexNo : this.countObj[resourceDisplayName],
      subscriptionIdentifier,
      env,
      identifier,
      appFam,
      applicationFamily,
      subscriptionName: this.subscriptionName,
    };
    const resourceName = this.resourceNameService.getResourceName(resourceData.resourceNameFormula || resourceDisplayName,options);
    return resourceName;
  }
  getPropertyValue(resourcePropertyList: any[], propertyName: string) {
    const propertyObj = resourcePropertyList.find(
      (rcs: any) => rcs?.propertyName === propertyName
    );
    return propertyObj ? propertyObj.propertyValue : '';
  }
  getLastElements(array: [], count: number) {
    return array.slice(Math.max(array.length - count, 0));
  }
}
