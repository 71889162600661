import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ErrorhandlingService } from '../errorhandling.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorhandlingService: ErrorhandlingService) {}
  GET_GRAFANA_TOKEN_URL = environment.UNIMATRIX_URL + 'Grafana';

  intercept(
    request: HttpRequest<string>,
    next: HttpHandler
  ): Observable<HttpEvent<string>> {
    // Check if the request URL is not the specific API URL
    if (request.url !== this.GET_GRAFANA_TOKEN_URL) {
      return next.handle(request).pipe(
        catchError((err) => {
          console.error(err.error);
          this.errorhandlingService.handleApiError(err);
          const error = (err?.error?.message) || err.statusText;
          return throwError(() => error);
        })
      );
    } else {
      // If it is the specific API URL, just forward the request without catching errors
      return next.handle(request);
    }
  }
}
