<app-navigation>
  <main>
    <h2>Dashboard</h2>
    <mat-progress-bar *ngIf="!loadSubscriptionListTable || !loadDraftStatusTable" mode="indeterminate"></mat-progress-bar>
    <section id="cssportal-grid">
      <mat-card class="subscription-table" tourAnchor="Welcome">
        <mat-card-header>
          <mat-card-title>Subscriptions</mat-card-title>
          <mat-card-subtitle>Select a Subscription</mat-card-subtitle>
          <button mat-icon-button (click)="getSubscriptionsAndSetTable(true)" matTooltip="Refresh Table"
            matTooltipPosition="above">
            <mat-icon>refresh</mat-icon>
          </button>
          <mat-paginator #subscriptionPaginator="matPaginator" [hidePageSize]="true"
            [pageSizeOptions]="[4]" style="margin-left: auto;">
          </mat-paginator>
        </mat-card-header>

        <mat-card-header>
          <mat-form-field style="width: 100%; margin-right: 10px;" class="rmv-field-padding" appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="subscriptionListFilter($event)" placeholder="ecad-pt-dev-tools" #input maxlength="50"
              appFormInput>
          </mat-form-field>
          <button [disabled]="!userRoles.blueprintCreate" mat-mini-fab color="primary" style="margin-left: auto"
            matTooltip="Request a New Suscription" matTooltipPosition="above" routerLink="/blueprint/subscription/new" tourAnchor="new-subscription" >
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content [class.disabled]="!loadSubscriptionListTable" style="height: 250px;">
          <mat-table [dataSource]="SubscriptionsListDataSource" *ngIf="SubscriptionsListDataSource;else noData" aria-label="Approvals Table" matSort>
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>Select</mat-header-cell>
              <mat-cell *matCellDef="let row;let i = index">
                <mat-radio-button (click)="$event.stopPropagation()" (change)="selectHandler(row)" [checked]="selection.isSelected(row)" [tourAnchor]="i === 0 ? 'select-option' : ''">
                </mat-radio-button>
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.name}} 
              <sup *ngIf="element.type === 'draft'" class="unimatrix-text-badge"> new </sup> 
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="cost">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Cost </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-chip>
                  {{ element.cost | currency: 'EUR' }}
                </mat-chip>
              </mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="subscriptionTableColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: subscriptionTableColumns;"> </mat-row>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">{{input.value ? 'No Subscriptions Found With Name :'+input.value : 'No Subscriptions Found.'}}</td>
            </tr>
          </mat-table>
        </mat-card-content>
      </mat-card>

      <mat-card class="blueprint-preview" tourAnchor="speed-and-performance" >
        <mat-card-header>
          <mat-card-title>Blueprint Preview</mat-card-title>
          <mat-card-subtitle>Here's a Preview of your Subscription</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content [class.disabled]="!(loadBpJson || loadSubscriptionListTable)" style="height: 250px; max-width: 600px; filter: grayscale(80%);">
          <app-blueprint-canvas [class.disabled]="!loadSubscriptionListTable" *ngIf="loadBpJson;else noData" [toolbar]="false" style="display: flex;background-color: rgba(255, 255, 255);"></app-blueprint-canvas>
        </mat-card-content>
      </mat-card>

      <mat-card class="blueprint-drafts" tourAnchor="what-is-angular">
        <mat-card-header>
          <mat-card-title>Blueprint Status</mat-card-title>
          <mat-card-subtitle>Your requests and submitted drafts</mat-card-subtitle>
          <mat-paginator #draftPaginator="matPaginator" [hidePageSize]="true"  style="margin-left: auto;" [showFirstLastButtons]="false" [pageSizeOptions]="[4]" showFirstLastButtons>
          </mat-paginator>
        </mat-card-header>

        <mat-card-header>
          <mat-form-field style="width: 100%; margin-right: 10px;" class="rmv-field-padding" appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="draftFilter($event)" placeholder="Enter Draft Name" #input maxlength="50"
              appFormInput>
          </mat-form-field>
        </mat-card-header>
        
        <mat-card-content [class.disabled]="!(loadDraftStatusTable && loadSubscriptionListTable)" style="height: 250px;">
          <mat-table [dataSource]="draftStatusTableDataSource" *ngIf="draftStatusTableDataSource;else noData">
            <caption></caption>
            <ng-container matColumnDef="draftName">
              <mat-header-cell *matHeaderCellDef> Draft Name </mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="ellipsis-text" matTooltip="{{element.draftName}}"> {{element.draftName}} </span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell *matCellDef="let element"> 
              <span *ngIf="element.status;else elseBlock"> {{ (element.status==="Completed"?'Deployments':element.status) + ' ' + element.subStatus }} </span>
              <ng-template #elseBlock>
                  <mat-chip *ngIf="element.isApprover" color="primary" [matMenuTriggerFor]="menu">
                    {{ element.currentStatus }} Approval 
                    <i-tabler name="chevron-down" style="width: 20px"></i-tabler>
                  </mat-chip>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item (click)="changeApprovalStatus('Approved',element.dguid)">
                    <span class="approve" style="padding: 4px; border-radius: 2px;">
                      <mat-icon class="icon" style="color: #00c853;">check</mat-icon>Approve Request</span>
                  </div>
                  <div mat-menu-item (click)="changeApprovalStatus('Rejected',element.dguid)">
                    <span class="reject" style="padding: 4px; border-radius: 2px;">
                      <mat-icon class="icon" style="color: #f44336;">close</mat-icon>Reject Request</span>
                  </div>
                  <mat-divider></mat-divider>
                  <a mat-menu-item [routerLink]="['/review', element.dguid]">
                    <mat-icon>visibility</mat-icon>
                    <span>Review</span>
                  </a>
                </mat-menu>
                <mat-chip *ngIf="!element.isApprover">
                  {{element.currentStatus}} from {{element.currentPendingLevel!==-1 ? 'L'+element.currentPendingLevel: ""}}
                  <i-tabler name="copy" matTooltip="Copy Request URL" matTooltipPosition="above" style="width: 18px;" (click)="copyRequestUrl(element.dguid)"></i-tabler>
                </mat-chip>
              </ng-template>
              </mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="archive">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button mat-icon-button color="warn" [routerLink]="['/blueprint/edit',element.dguid]" [hidden]="true">
                      <mat-icon>archive</mat-icon>
                    </button>  
              </mat-cell>
            </ng-container>
      
            <mat-header-row *matHeaderRowDef="draftStatusTableColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: draftStatusTableColumns;"></mat-row>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4"> No requests and submitted drafts</td>
            </tr>
          </mat-table>
        </mat-card-content>
      </mat-card>

      <mat-card class="resource-overview" style="display: grid;" tourAnchor="graph">
        <mat-card-header>
          <mat-card-title>Resource Overview</mat-card-title>
          <mat-card-subtitle>Select on a Resource Type to View Cost for each Resource</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content [class.disabled]="!(loadBpJson || loadSubscriptionListTable)" class="content">
          <ngx-charts-advanced-pie-chart [class.disabled]="!loadSubscriptionListTable" *ngIf="loadBpJson;else noData" [results]="resourceOverview" (select)="onResourceDetailSelect($event)" [customColors]="customColors">
          </ngx-charts-advanced-pie-chart>
        </mat-card-content>
      </mat-card>

      <mat-card class="resource-details">
        <mat-card-header>
          <mat-card-title>Resource Details</mat-card-title>
          <mat-card-subtitle>Resource Type: <span  style="color: #00c853;">{{resourceTypeName}}</span></mat-card-subtitle>
            <mat-paginator style="margin-left: auto" #resourceDetailsPaginator="matPaginator" [hidePageSize]="true" [showFirstLastButtons]="false" [pageSizeOptions]="[4]" showFirstLastButtons>
            </mat-paginator>
        </mat-card-header>
        <mat-card-header>
          <mat-form-field style="width: 100%; margin-right: 10px;" class="rmv-field-padding" appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="resourceListFilter($event)" placeholder="ecad-pt-dev-tools" #input maxlength="50"
              appFormInput>
          </mat-form-field>
        </mat-card-header>
        <mat-card-content [class.disabled]="!(loadBpJson || loadSubscriptionListTable)" style="height: 300px;">
          <mat-table [class.disabled]="!loadSubscriptionListTable" [dataSource]="resourceDetailsTableDataSource" matSort *ngIf="loadBpJson && !isEmptyResourceDetails ;else noData">
            <caption></caption>
            <ng-container matColumnDef="resource">
              <mat-header-cell *matHeaderCellDef> Resources </mat-header-cell>
              <mat-cell *matCellDef="let resource">{{ resource.azid ? resource.azid.split('/').pop() : resourceTypeName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="cost">
              <mat-header-cell *matHeaderCellDef> Cost </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-chip>
                  {{ element.currentCost | currency: 'EUR' }}
                </mat-chip>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="resourceDetailsColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: resourceDetailsColumns;"></mat-row>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">Please select Resource from Overview to display details</td>
            </tr>
          </mat-table>
        </mat-card-content>
      </mat-card>
      <div class="footer">
        <app-footer></app-footer>
      </div>
    </section>
    <ng-template #noData>
      <div class="center-container">
      <p>Sorry, cannot load any data at the moment.</p>
    </div>
    </ng-template>
    <tour-step-template></tour-step-template>
  </main>
</app-navigation>