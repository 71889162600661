import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  private bypassGuard: boolean = false;
  public title = new BehaviorSubject('Title');
  public isExpanded = new BehaviorSubject(true);

  setTitle(title: string) {
    this.title.next(title);
  }

  setDisableCanDeactivateGuard(value: boolean) {
    this.bypassGuard = value;
  }

  getDisableCanDeactivateGuard(): boolean {
    return this.bypassGuard;
  }

}
