import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TenantsCatalogueService } from '../../pages/tenants-catalogue/tenants-catalogue.service';

@Component({
  selector: 'app-add-tenant-dialog',
  templateUrl: './add-tenant-dialog.component.html',
  styleUrls: ['./add-tenant-dialog.component.scss']
})
export class AddTenantDialogComponent{
  tenantForm: FormGroup;
  errorMessage: string = '';

  constructor(
    public dialogRef: MatDialogRef<AddTenantDialogComponent>,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private _tenantsCatalogueService: TenantsCatalogueService,
  ) {
    this.tenantForm = this.formBuilder.group({
      tenantName: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.tenantForm.valid) {
      const tenantName = this.tenantForm.get('tenantName')?.value;
      this._tenantsCatalogueService.createTenant(tenantName).subscribe({
        next: (response) => {
          if (response && response.status === 201) {
            this.dialogRef.close(tenantName);
          } else {
            this.errorMessage = 'An error occurred while adding the tenant.';
            this.tenantForm.get('tenantName')?.setErrors({ apiError: true });
          }
        },
        error: (error) => {
          this.errorMessage = 'Tenant name already exists.';
          this.tenantForm.get('tenantName')?.setErrors({ apiError: true });
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}