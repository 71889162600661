import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BlueprintExportService {

  exportSvg(svgElementId: string, filename: string) {
    const svgElement = document.getElementById(svgElementId);
    if (!svgElement) {
      console.error('SVG element not found');
      return;
    }
    
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);

    // Add inline styles
    const svgStringWithStyles = this.addStylesToSvg(svgString, svgElementId);

    const svgBlob = new Blob([svgStringWithStyles], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);

    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }

  private addStylesToSvg(svgString: string, svgElementId: string): string {
    const styleSheets = Array.from(document.styleSheets);
    let styles = '';

    styleSheets.forEach(sheet => {
      try {
        if (sheet.cssRules) {
          Array.from(sheet.cssRules).forEach(rule => {
            styles += rule.cssText;
          });
        }
      } catch (e) {
        console.warn('Could not access stylesheet:', sheet.href);
      }
    });

    const styleElement = `<style type="text/css"><![CDATA[${styles}]]></style>`;
    const defsPosition = svgString.indexOf('<defs>');
    
    if (defsPosition > -1) {
      // Insert styles inside <defs> tag if it exists
      svgString = `${svgString.slice(0, defsPosition + 6)}${styleElement}${svgString.slice(defsPosition + 6)}`;
    } else {
      // Otherwise, insert styles right after <svg> tag
      const svgTagEnd = svgString.indexOf('>') + 1;
      svgString = `${svgString.slice(0, svgTagEnd)}${styleElement}${svgString.slice(svgTagEnd)}`;
    }

    return svgString;
  }
}