import { Injectable } from '@angular/core';
import { Observable,of} from 'rxjs';
import { DialogService } from 'src/services/confirm-dialog.service';
import { CoreService } from './components/shared/snackbar/snackbar.service';
import { CookieService } from 'ngx-cookie-service';
import { SessionService } from 'src/services/session.service';
import { LoginService } from 'src/services/login.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorhandlingService {
  constructor(
    private dialogService: DialogService,
    private _coreService: CoreService,
    private sessionService: SessionService,
    private loginService: LoginService,
    private cookie: CookieService,
  ) {}

  handleApiError(error: any): Observable<boolean> {
    if (error.status === 401) {
      this.showSessionExpiredDialog()
    }
    else if (error.status === 403) {
      this._coreService.openSnackBar('403: You are not Authorized to perform this action', 2000, 'warn-snackbar');
    }
    else if (error.status === 404) {
      this._coreService.openSnackBar('Resource not found', 2000, 'warn-snackbar');
    }
    else if (error.status === 503) {
      this._coreService.openSnackBar('Service Temporarily Unavailable', 2000, 'warn-snackbar');
    }
    else if (error.status === 500) {
      this._coreService.openSnackBar('500 Error', 500, 'warn-snackbar');
    }
    else {
      // Handle other error cases
      // Additional error handling logic for other errors
    }
    return of(false); // Return an empty observable as a default fallback
  }

  private showSessionExpiredDialog(): void {
    this.dialogService.confirmDialog({
      title: "Session Expired",
      message: "We request you to login again",
      confirmCaption: "Continue",
      cancelCaption: "Cancel"
    })
    .subscribe((confirmed) => {
      if (confirmed) {
        this.sessionService.clearCookieAndSessionStorage();
        location.reload();
      }
      else {
        this._coreService.openSnackBar('You will Need to Logout', 10000, 'warn-snackbar');   
      }
    });
  }
}