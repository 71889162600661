<h2  mat-dialog-title>{{data ? 'Update': 'Create'}} a Field</h2>

<form [formGroup]="newFieldFormGroup" (ngSubmit)="onFormSubmit()">
    <mat-dialog-content style="display: grid; gap: 10px;">

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>propertyName</mat-label>
                <input matInput formControlName="propertyName" required="" maxlength="50" appFormInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>propertyNameAlias</mat-label>
                <input matInput formControlName="propertyNameAlias" maxlength="50" appFormInput>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-radio-group formControlName="userInput" required="">
                <mat-label>UserInput: </mat-label>
                <mat-radio-button [value]=true>true</mat-radio-button>
                <mat-radio-button [value]=false>false</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group formControlName="editable" required="">
                <mat-label>Editable: </mat-label>
                <mat-radio-button [value]=true>true</mat-radio-button>
                <mat-radio-button [value]=false>false</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>dataType</mat-label>
                <mat-select formControlName="dataType" required="">
                    <mat-option *ngFor="let val of dataType" [value]="val"> {{val}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>itemList</mat-label>
                <input matInput type="itemList" formControlName="itemList">
                <mat-hint>Dropdown Values (seperated by ;)</mat-hint>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline" required="">
                <mat-label>Source</mat-label>
                <mat-select formControlName="source" required="false" (selectionChange)="onSelectionChanged($event.value)">
                    <mat-option *ngFor="let src of source" [value]="src.value"> {{src.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>type</mat-label>
                <input matInput type="type" formControlName="type">
                <mat-hint>Ex: "type": "azurerm_resource_group"</mat-hint>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline" required="">
                <mat-label>path</mat-label>
                <input matInput type="path" formControlName="path">
                <mat-hint>Ex: properties.isNfsV3Enabled</mat-hint>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-stroked-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button *ngIf="data ? FormbuilderEditPermissions : FormbuilderAddPermissions" mat-flat-button color="primary" type="submit" [mat-dialog-close]="true" [disabled]="!newFieldFormGroup.valid" >{{data ? 'Update': 'Create'}}</button>
    </mat-dialog-actions>
</form>