<app-navigation>
    <main>
        <h2>Admin Page</h2>
        <section class="admin-grid">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>App Family</mat-card-title>
                    <mat-paginator #appFamilyPaginator="matPaginator" [hidePageSize]="true"
                    [showFirstLastButtons]="false" [pageSizeOptions]="[5]" showFirstLastButtons style="margin-left: auto;">
                </mat-paginator>
                </mat-card-header>
                <mat-card-header>
                    <mat-form-field class="rmv-field-padding" appearance="outline" style="width: 100%; margin-right: 10px;">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="appFamilyList($event)"
                            placeholder="Platform_Team" #input maxlength="50" appFormInput>
                    </mat-form-field>
                    <button mat-mini-fab="primary" color="primary" (click)="appFamilyDialog()"
                        matTooltip="Add new App Family">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-card-header>
                <mat-card-content>
                <mat-progress-bar *ngIf="!loadTable" mode="indeterminate"></mat-progress-bar>
                <table mat-table [ngStyle]="{'opacity': loadTable ? '1' : '0.3' }" [dataSource]="appFamilyDataSource" matSort>
                    <caption></caption>
                    <ng-container matColumnDef="appFamilyName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> App Family Name </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-chip color="primary" selected> {{row.AppFamilyName}}
                            </mat-chip>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="grafanaId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Grafana Org Id </th>
                        <td mat-cell *matCellDef="let row">
                        {{row.GrafanaOrgId}}
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef>  </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button color="primary" [routerLink]="['',row.id]" [hidden]="true">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="appFamilyColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: appFamilyColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4"> No Forms Found With Name : {{input.value}}</td>
                    </tr>
                </table>
                </mat-card-content>
            </mat-card>
        </section>

    </main>
    
</app-navigation>
<router-outlet></router-outlet>