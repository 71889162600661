<app-navigation>
  <main>
    <mat-card style="margin-bottom: 2rem;">
      <mat-card-header>
        <mat-card-title>Deployments</mat-card-title>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="end" (selectedTabChange)="onTabChange($event)"
          style="flex: 1;">
          <mat-tab *ngFor="let tab of tabMapping" [label]="tab"></mat-tab>
        </mat-tab-group>
      </mat-card-header>
      <mat-form-field style="width: 98%;padding: 1rem 1rem;" class="rmv-field-padding" appearance="outline">
        <input matInput (keyup)="applyFilter($event)" placeholder="Search">
      </mat-form-field>
      <div class="table-container">
        <div class="table-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="table-rate-limit-reached" *ngIf="isRateLimitReached">
            We are sorry, but we cannot show any results at the moment.
          </div>
        </div>
        <mat-card-content>
          <div class="table-table-container">
            <table aria-label="Deployments" mat-table [dataSource]="data" class="table-table" matSort matSortActive="createdOn"
              matSortDisableClear matSortDirection="desc">
              <ng-container matColumnDef="draftSummary">
                <th mat-header-cell *matHeaderCellDef> Draft Summary </th>
                <td mat-cell *matCellDef="let element">
                  <a class="draft-name" [routerLink]="['/review', element.dguid]"
                    matTooltip="{{element.summary ? element.summary : 'No Summary Added'}}">
                    {{ element.draftName }}:
                    <span>{{element.summary ? element.summary : 'No Summary Added'}}</span>
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="subscriptionName">
                <th mat-header-cell *matHeaderCellDef>Subscription</th>
                <td mat-cell *matCellDef="let row">{{row.subscriptionName}}</td>
              </ng-container>

              <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Requester</th>
                <td mat-cell *matCellDef="let row">{{row.userName}}</td>
              </ng-container>

              <ng-container matColumnDef="subStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let row">
                  <ng-container *ngIf="row.subStatus==='Failed' || row.subStatus==='PartiallyFailed'; else statusBlock">
                    <button mat-button [disabled]="!userRoles.blueprintCreate"
                      [routerLink]="['/blueprint/edit',row.dguid]" [style.color]="'#f44336 !important'"
                      [style.background-color]="'rgba(244, 67, 54, 0.1)'">{{row.subStatus}}
                    </button>
                  </ng-container>
                  <ng-template #statusBlock>
                    {{(row.status === "Approval" && row.subStatus === "Completed") ? "Queued" : row.subStatus}}
                  </ng-template>
                </td>
              </ng-container>
              <ng-container matColumnDef="createdOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                  Created
                </th>
                <td mat-cell *matCellDef="let row">{{row.createdOn | epochToDate}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell">No Deployments</td>
              </tr>
            </table>
          </div>
        </mat-card-content>
        <mat-card-footer>
          <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
        </mat-card-footer>
      </div>
    </mat-card>

  </main>
</app-navigation>