import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GrafanaService {

  GET_GRAFANA_TOKEN_URL = environment.UNIMATRIX_URL + 'Grafana';

  constructor(private http: HttpClient) {
  }
  
  getGrafanaToken(jwt: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `${jwt}`,
    });
    const requestOptions: object = {
      headers: headers,
      observe: 'response',
      withCredentials: true
    }
    return this.http.get<any>(this.GET_GRAFANA_TOKEN_URL, requestOptions);
  }
}
