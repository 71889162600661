<h1 mat-dialog-title>Create a New Tenant</h1>
<mat-dialog-content>
  <form [formGroup]="tenantForm" (ngSubmit)="onSubmit()" id="tenantForm">
    <mat-form-field appearance="outline" style="margin-top: 5px; width: 100%;">
      <mat-label>Tenant Name</mat-label>
      <input matInput formControlName="tenantName" required>
      <mat-error *ngIf="tenantForm.get('tenantName')?.hasError('required')">
        Tenant Name is required
      </mat-error>
      <mat-error *ngIf="tenantForm.get('tenantName')?.hasError('apiError')">
        {{ errorMessage }}
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button type="button" (click)="onCancel()">Cancel</button>
  <button mat-button type="submit" form="tenantForm">Submit</button>
</mat-dialog-actions>