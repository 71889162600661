import { Component, Inject } from '@angular/core';
import { CoreService } from '../../shared/snackbar/snackbar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RoleData } from '../../pages/roles-table/roles';
import { UserService } from 'src/services/roles.service';
import { map } from 'rxjs';
import { FormbuilderService } from '../../pages/formbuilder/formbuilder.service';

@Component({
  selector: 'app-draft-template-activation-dialog',
  templateUrl: './draft-template-activation-dialog.component.html',
  styleUrls: ['./draft-template-activation-dialog.component.scss']
})
export class DraftTemplateActivationDialogComponent {
  selected:any
  shareRoles:any;
  activationform!: FormGroup;
  sharedUserRolesId: number[] = [];
  shareRoleName: any;
  isChecked?:boolean;
  newSelectControl = new FormControl('');
  constructor(
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formbuilderService: FormbuilderService,
    private _coreService: CoreService,
    private userService: UserService,
    public dialogRef: MatDialogRef<DraftTemplateActivationDialogComponent>
  ) {
    this.isChecked=data.isTemplateActive;
  }
  

  ngOnInit() {
    this.roles();
  }
  roles(){
    this.userService.getUsers().pipe(
      map(response => response.rolesData.roles)
    ).subscribe({
      next: (roles: RoleData[]) => {
        this.shareRoles=roles;
        this.selected = this.shareRoles
          .filter((role: { id: any; }) => this.data.shareToRoleIds.some((shareRoleId: { roleId: any; }) => shareRoleId.roleId === role.id))
          .map((role: { roleName: string; }) => role.roleName);
      },
      error: (error) => {
        this._coreService.openSnackBar('Error in fetching Roles List', 1000, 'warn-snackbar');
        console.error('Error in fetching Roles List: ', error);
      }
    });
  }
  
  onSubmit() {
    if (this.selected) {
      this.sharedUserRolesId = this.shareRoles
        .filter((role: { roleName: string; }) => this.selected.includes(role.roleName))
        .map((role: { id: any; }) => role.id);
      this.dialogRef.close({ sharedUserRolesId: this.sharedUserRolesId, isActive: this.isChecked });
    } else {
      this.dialogRef.close();
    }
    }
  toggleActivation(){
    if (this.isChecked === true) {
      this._coreService.openSnackBar('Switched to Activate Draft', 1000);
    }
    else {
      this._coreService.openSnackBar('Switched to Deactivate Draft', 1000);
    }
  }
  }

