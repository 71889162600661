import { Injectable } from '@angular/core';
import { CoreService } from 'src/app/components/shared/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class Base64Service {

  constructor(private _coreService: CoreService) { }

  // Function to encode a string to Base64
  encode(input: string): string {
    return btoa(encodeURIComponent(input));
  }

  // Function to decode a Base64 string
  decode(input: string): string {
    try {
      return decodeURIComponent(window.atob(input));
    } catch (e) {
      console.error('Error decoding base64 string:', e);
      this._coreService.openSnackBar('Malformed code, Returning string', 2000, 'warn-snackbar');
      return input;
    }
  }
}