import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RoleData,AzGroup, RootRolesData } from '../app/components/pages/roles-table/roles';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  serviceUrl = environment.API_URL + 'RBAC';
  azdataUrl = environment.API_URL + 'RBACAzureGroupsList';
  GET_USER_ROLES_URL = environment.API_URL + 'GetUserRoles';

  constructor(private http: HttpClient) {}

  getUserRoles(): Observable<HttpResponse<string>> {
    const requestOptions: object = {
      observe: 'response',
      responseType: 'text'
    }
    return this.http.get<HttpResponse<string>>(this.GET_USER_ROLES_URL, requestOptions);
  }

  getUsers(): Observable<RootRolesData> {
    return this.http
      .get<RootRolesData>(this.serviceUrl);
  }

  getAzList(): Observable<AzGroup[]> {
      return this.http
      .get(this.azdataUrl, { observe: 'response' })
      .pipe<AzGroup[]>(map((data:object) => data as AzGroup[]));
  }

  updateUser(user: RoleData): Observable<RoleData> {
    return this.http.post<RoleData>(this.serviceUrl, user);
  }

  addUser(user: RoleData): Observable<RoleData> {
    return this.http.post<RoleData>(this.serviceUrl, user);
  }

  deleteUser(id: string): Observable<RoleData> {
    return this.http.delete<RoleData>(`${this.serviceUrl}?id=${id}`);
  }

}
