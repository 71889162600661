import { IStepOption } from 'ngx-ui-tour-md-menu';

export const UnimatrixStepOption: IStepOption[] = [
  {
    anchorId: 'Welcome',
    title: 'Welcome',
    content: 'Welcome to the Unimatrix tour!',
    enableBackdrop: true,
    placement: {
      xPosition: 'before',
      yPosition: 'above',
    }
  },
  {
    anchorId: 'new-subscription',
    title: 'Create a Subscription',
    content: 'You can Select a subscription from the list to see Drafts and Resources in the Subscription',
  },
  {
    anchorId: 'select-option',
    title: 'Select a Subscription',
    content: 'You can Select a subscription from the list to see Drafts and Resources in the Subscription',
    enableBackdrop: true,
    placement: {
      xPosition: 'before',
      yPosition: 'above',
    }
  },
  {
    anchorId: 'speed-and-performance',
    title: 'Welcome',
    content: 'Welcome to the demo tour!',
  },
  {
    anchorId: 'what-is-angular',
    title: 'Welcome',
    content: 'Welcome to the demo tour!',
  },
  {
    anchorId: 'graph',
    title: 'Welcome',
    content: 'Welcome to the demo tour!',
  },
];