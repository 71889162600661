<app-navigation>
    <main>
        <section class="review-section">
            <div>
            <button mat-button style="width: fit-content;">
                <div class="back-button opacity-60" (click)="goBack()">
                    <i-tabler name="chevron-left" class="toolbar-icon"></i-tabler>
                    <span style="margin-left: 5px;">Back</span>
                </div>
            </button>
            <span class="opacity-60">/</span>
            <button mat-button style="width: fit-content;">
                <div>
                    <span style="margin-left: 5px;">Blueprint Review</span>
                </div>
            </button>
           </div>
           <mat-progress-bar *ngIf="!loadTable" mode="indeterminate"></mat-progress-bar>
            <div class="review-grid">
                <mat-card id="item-0">
                    <mat-card-header>
                        <div style="width: 100%;padding: 0.5rem;">
                        <mat-card-title>Review Draft: {{draftDetails.draftName}}</mat-card-title>
                        <mat-card-title>
                            <div class="draft-info">
                                <span>Total Cost: <span>{{totalPreviousCost.toFixed(2) | currency:'EUR'}}</span> --> {{totalCurrentCost.toFixed(2) | currency:'EUR'}}</span>
                                <span class="opacity-60">Requester Name: {{draftDetails.requesterName}}</span>
                                <span class="opacity-60">Subscription Name: {{draftDetails.subscriptionName}}</span>
                                <span class="opacity-60">Created On: {{draftDetails.requestDate | latestDate | epochToDate}}</span>
                            </div>
                            <span class="opacity-60" style="font-size: 15px;">Summary: {{draftDetails.summary ? draftDetails.summary : "No summary added"}}</span>
                        </mat-card-title>
                    </div>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div *ngIf="isNewSubRequest">
                            <p>{{ isNewSubRequest }}</p>
                        </div>
                        <div *ngFor="let table of tables">
                            <div *ngIf="table.dataSource.data.length>0">
                            <mat-card-header>
                                <mat-card-title>{{ table.title === 'Subscription' ?  table.title + ' ' + subUserActionType +'d' : table.title }}</mat-card-title>
                                <mat-card-subtitle>{{ table.subtitle }}</mat-card-subtitle>
                            </mat-card-header>
                            <div style="padding: 0.5rem;filter: brightness(135%);">
                                    <table aria-label="Review" mat-table [dataSource]="table.dataSource" matSort>
                                        <ng-container matColumnDef="resourceName">
                                            <th mat-header-cell *matHeaderCellDef> Resource Name </th>
                                            <td mat-cell *matCellDef="let row">
                                                    <ng-container *ngIf="(row.currentTotalCost || row.previousTotalCost); else resourceName">
                                                      Total
                                                    </ng-container>
                                                    <ng-template #resourceName><span class="opacity-70"> {{ row.resourceName }} </span></ng-template>
                                                <sup *ngIf="row.userActionType!=='' && row.isChanged "class="unimatrix-text-badge">sync</sup>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="resourceType">
                                            <th mat-header-cell *matHeaderCellDef> Resource Type </th>
                                            <td mat-cell *matCellDef="let row">
                                               <span class="opacity-70"> {{row.resourceDisplayName}} </span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cost">
                                            <th mat-header-cell *matHeaderCellDef> Cost </th>
                                            <td mat-cell *matCellDef="let row">
                                                <span *ngIf="!(row.currentTotalCost && row.previousTotalCost)">
                                                    <span
                                                      *ngIf="row.currentCost-row.previousCost!== 0 && (row.userActionType==='update' || row.userActionType === 'delete')"
                                                      class="opacity-70">
                                                      <span style="filter: opacity(60%);">{{row.previousCost | currency:'EUR'}}</span> -->
                                                    </span>
                                                    <span class="opacity-70">{{row.currentCost | currency:'EUR'}}</span>
                                                    </span>
                                                    <span *ngIf="(row.currentTotalCost || row.previousTotalCost)">
                                                      <span
                                                        *ngIf="row.currentTotalCost-row.previousTotalCost!== 0"
                                                        class="opacity-70">
                                                        <span class="opacity-60">{{row.previousTotalCost | currency:'EUR'}}</span> -->
                                                      </span>
                                                      <span class="opacity-70">{{row.currentTotalCost | currency:'EUR'}}</span>
                                                      <span *ngIf="row.previousTotalCost!==0 && row.currentTotalCost!==0" class="opacity-70">
                                                        <span *ngIf="row.currentTotalCost-row.previousTotalCost !== 0">
                                                          ({{ row.currentTotalCost-row.previousTotalCost < 0 ? '↓' : '↑'}}{{(getAbsoluteValue(row.currentTotalCost-row.previousTotalCost)/row.previousTotalCost*100).toFixed(2) }}%)
                                                        </span>
                                                      </span>
                                                      </span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="resourceDetailsTableColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: resourceDetailsTableColumns;"></tr>
                                        <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="4"> No resources created </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="noData" *ngIf="areAllTablesEmpty() && loadTable">
                            <p>We are sorry, but we cannot show any data to review at the moment.</p>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card id="item-1">
                    <mat-card-header>
                        <mat-card-title> Approval History </mat-card-title>
                        <mat-card-subtitle>A list of all the approvals done for this blueprint</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content style="overflow-y: auto;">
                            <div *ngFor="let approval of approvalHistoryDataSource.data">
                                <mat-card style="filter: brightness(135%);">
                                    <mat-card-header style="font-size: 18px;">Level {{approval.approvalLevel}} Approver</mat-card-header>
                                    <mat-card-content>
                                    <mat-divider></mat-divider>
                                        <span class="opacity-60">Approver: {{approval.approverName}}</span>
                                        <span class="opacity-60">Status: {{approval.approvalStatus}}</span>
                                        <span class="opacity-60">Date: {{approval.approvalDateTime | epochToDate }}</span>
                                        <span class="opacity-60">Comments: {{approval.approvalComments ? approval.approvalComments : 'No Comments'}}</span>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="noData" *ngIf="approvalHistoryDataSource.data.length === 0 && loadTable">
                                <p>No Approval History</p>
                            </div>
                    </mat-card-content>
                    <mat-divider></mat-divider>
                    <div class="actions" *ngIf="!showViewButton">
                        <div [matTooltip]="!draftDetails.isApprover ? 'No permission to Approve / Reject' : ''">
                            <button style="margin: 5px;" mat-stroked-button color="warn" matTooltip="Reject"
                                (click)="changeApprovalStatus('Rejected')" [disabled]="!draftDetails.isApprover">Reject</button>
                            <button style="margin: 5px;" mat-flat-button color="primary" matTooltip="Approve"
                                (click)="changeApprovalStatus('Approved')" [disabled]="!draftDetails.isApprover">Approve</button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </section>
    </main>
</app-navigation>