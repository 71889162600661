import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'epochToDate'
})
export class EpochToDatePipe implements PipeTransform {

  transform(epoch: number): string {
    const date = new Date(0);
    date.setUTCSeconds(epoch);
    const formattedDate = date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const formattedTime = date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12:false });
    return `${formattedDate} at ${formattedTime}`;
  }

}
