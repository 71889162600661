import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { UsersubscriptionService } from "src/services/usersubscription.service";
import { NavigationService } from "src/services/navigation.service";
import { HttpResponse } from "@angular/common/http";
import { CoreService } from "../../shared/snackbar/snackbar.service";
import { AesencryptionService } from "src/services/encryption.service";
import { catchError, forkJoin, map, of, throwError } from "rxjs";
import { BlueprintCloneDialogComponent } from 'src/app/components/dialog/blueprint-clone-dialog/blueprint-clone-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { BlueprintDataService } from "src/services/blueprint-data.service";
import { RoleData } from "../roles-table/roles";
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { UnimatrixStepOption } from './dashboard.tour';
import { ApprovalsService } from "../approval/approvals.service";
import { DeploymentsService } from "../deployment/deployments.service";
import { ApprovalCommentDialogComponent } from "../../dialog/approval-comment-dialog/approval-comment-dialog.component";
import { BlueprintsService } from "../blueprint/blueprints.service";
import { HeaderService } from "../../shared/header/header.service";

export enum SelectType {
  single,
  multiple
}

@Component({
  selector: 'app-resourcedashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('subscriptionPaginator') subscriptionPaginator!: MatPaginator;
  @ViewChild('draftPaginator') draftPaginator!: MatPaginator;
  @ViewChild('resourceDetailsPaginator') resourceDetailsPaginator!: MatPaginator;

  SubscriptionsListDataSource!: MatTableDataSource<any>;
  subscriptionTableColumns: string[] = ['select', 'name', 'cost'];
  draftStatusTableDataSource!: MatTableDataSource<any>;
  draftStatusTableColumns: string[] = ['draftName', 'status'];
  resourceDetailsTableDataSource!: MatTableDataSource<any>;
  resourceDetailsColumns: string[] = ['resource', 'cost']

  userRoles: RoleData;
  selection = new SelectionModel<any>(true, []);
  displayType = SelectType.single;
  subdata: any;
  resourceOverview: any;
  resourceDetails: any;
  subid = "";
  type = "";
  loadSubscriptionListTable = false;
  loadBpJson = false;
  loadDraftStatusTable = false;
  resourceTypeName = "";
  customColors!: { name: any; value: string; }[];
  userDetails: any;
  isEmptyResourceDetails: boolean=false;

  constructor(
    private usersubscription: UsersubscriptionService,
    private navigationService: NavigationService,
    private _coreService: CoreService,
    private aesencryptionService: AesencryptionService,
    public dialog: MatDialog,
    private usersubscriptionService: UsersubscriptionService,
    private blueprintDataService: BlueprintDataService,
    private _blueprintService: BlueprintsService,
    private _deploymentService: DeploymentsService,
    private _approvalService: ApprovalsService,
    private readonly tourService: TourService,
    private headerService: HeaderService
  ) {
    this.userRoles = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
    this.userDetails = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userdetails')) || '');
  }

  ngOnInit() {
    this.getDraftsStatusTable();
    this.navigationService.setTitle('Data');
    this.getSubscriptionsAndSetTable(false);
    this.resourceDetailsTableDataSource = new MatTableDataSource();
    this.tourService.initialize(UnimatrixStepOption, {
      disablePageScrolling: true,
      backdropConfig: {
        backgroundColor: 'rgba(0,0,0,0.3)',
      },
    });
    // this.startTour();
  }

  startTour() {
    this.tourService.start();
  }

  selectHandler(row: any) {
    if (this.displayType == SelectType.single) {
      if (!this.selection.isSelected(row)) {
        this.selection.clear();
      }
    }
    this.selection.toggle(row);
    this.subid = row.subid;
    this.type = row.type;
    this.getBlueprintJson(row.dguid, row.type);
    this._coreService.openSnackBar('Selected ' + this.selection.selected[0].name,1000,'success-snackbar');
  }

  onChange(typeValue: number) {
    this.displayType = typeValue;
    this.selection.clear();
  }

  onResourceDetailSelect(event: any) {
    this.resourceTypeName = event.name
    const selectedResource = this.resourceDetails.find((resource: any) => resource.resourceGeneralDisplayName === event.name);
    const resourceDataList = selectedResource?.resourceList?.map((resourceData: any) => resourceData.resourceData) || [];
    resourceDataList.sort((a: any, b: any) => b.currentCost - a.currentCost);
    this.resourceDetailsTableDataSource = new MatTableDataSource(resourceDataList);
    this.resourceDetailsTableDataSource.paginator = this.resourceDetailsPaginator;
    this.customColors = [
      {
        name: event.name,
        value: 'aqua'
      }
    ];
  }

  getSubscriptionsAndSetTable(refreshSubscription: boolean): void {
    this.loadSubscriptionListTable = false;
    this.usersubscription.getSubscriptionList(refreshSubscription).pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error fetching subscriptions list', 2000, 'warn-snackbar');
        this.loadSubscriptionListTable = true;
        const err = ': Failed fetching subscriptions list';
        return throwError(() => err);
      })
    ).subscribe((res: any) => {
      this.loadSubscriptionListTable = true;
      this.subdata = res.body.value;
      this.subdata.sort((a: any, b: any) => b.cost - a.cost);
      this.SubscriptionsListDataSource = new MatTableDataSource<any>(this.subdata);
      this.SubscriptionsListDataSource.sort = this.sort;
      this.SubscriptionsListDataSource.paginator = this.subscriptionPaginator;
      if (this.displayType === SelectType.single && this.subdata.length > 0) {
        this.selection.select(this.subdata[0]); 
        const firstDguid = this.subdata[0].dguid;
        this.subid = this.subdata[0].subid;
        this.type = this.subdata[0].type;
        this.getBlueprintJson(firstDguid, this.type);
      }
    });
  }

  getDraftsStatusTable() {
    if (this.userRoles.blueprintCreate || this.userRoles.blueprintModify || this.userRoles.blueprintDelete || this.userRoles.blueprintApproval_L1 || this.userRoles.blueprintApproval_DevOps) {
      forkJoin({
        approvals: this.getApprovalsList().pipe(
          catchError(error => {
            return of([]); // Return an empty array on error
          })
        ),
        deployments: this.getDeploymentsList().pipe(
          catchError(error => {
            return of([]); // Return an empty array on error
          })
        )
      }).subscribe(({ approvals, deployments }) => {
        this.getBlueprintStatusData(approvals, deployments);
      });
    }
    else{
      this.loadDraftStatusTable=true;
    }
  }

  getApprovalsList() {
    return this._approvalService.getBpApprovalList().pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error fetching approvals status list.', 2000, 'warn-snackbar');
        const err = ': Failed fetching approvals status list.';
        return throwError(() => err);
      }),
      map((res: any) => {
        res.body.reverse();
        return res.body.filter((draft: { isApprover: any; requesterName: string; currentPendingLevel: any }) =>
          draft.isApprover || (draft.requesterName === this.userDetails.UserName && draft.currentPendingLevel > 0)
        );
      })
    );
  }

  getDeploymentsList() {
    return this._deploymentService.getUsersBpDeploymentsList().pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error fetching deployments status list', 2000, 'warn-snackbar');
        const err = ': Failed fetching deployments status list.';
        return throwError(() => err);
      }),
      map((res: any) => {
        return res.body.deployments;
      })
    );
  }

  getBlueprintStatusData(approvalsList: any,deploymentsList: any){
      const combinedDrafts = [...approvalsList, ...deploymentsList];
      this.draftStatusTableDataSource = new MatTableDataSource<any>(combinedDrafts);
      this.draftStatusTableDataSource.paginator = this.draftPaginator;
      this.draftStatusTableDataSource.data = this.draftStatusTableDataSource.data.sort((a, b) => b.isApprover - a.isApprover);
      const pendingApprovalsCount = this.draftStatusTableDataSource.data.filter(item => item.isApprover).length;
      const encryptedPendingApprovalsCount = this.aesencryptionService.encryptUsingAES256(pendingApprovalsCount)
      sessionStorage.setItem('pendingRequestsCount', encryptedPendingApprovalsCount);
      this.headerService.triggerRefreshHeader();  
      this.loadDraftStatusTable=true;
  }
  
  getBlueprintJson(dguid: string, type: string) {
    this.isEmptyResourceDetails=false;
    if(this.userRoles.blueprintView){
    if (type == "draft") {
      this._blueprintService.getBpUserDraftJson(dguid).subscribe({
        next: (response: HttpResponse<any>) => {
          this.shareBlueprintData(response.body)
          this.getResourceInfo(response.body);
        },
        error: (error) => {
          this._coreService.openSnackBar('Error fetching blueprint json', 2000, 'warn-snackbar');
          console.error('Error fetching blueprint json: ', error);
        }
      });
    }
    else {
      this.usersubscriptionService.getFQSubscriptionBlueprint(dguid).subscribe({
        next: (response: HttpResponse<any>) => {
          this.shareBlueprintData(response.body)
          this.getResourceInfo(response.body);
        },
        error: (error) => {
          this._coreService.openSnackBar('Error fetching blueprint json', 2000, 'warn-snackbar');
          console.error('Error fetching blueprint json: ', error);
        }
      });
    }
  }
  }

  getResourceInfo(blueprintJsonData: any) {
    const result = blueprintJsonData.resourceInventoryData.map((resource: any) => ({
      name: resource.resourceGeneralDisplayName,
      value: resource.resourceList.length
    }));
    this.resourceOverview = result;
    if(blueprintJsonData.resourceInventoryData.length > 0){
    this.resourceDetails = blueprintJsonData.resourceInventoryData
    const firstResource = this.resourceOverview[0];
    this.onResourceDetailSelect(firstResource);
    }
    else{
      this.isEmptyResourceDetails=true;
    }
  }

  shareBlueprintData(blueprintJsonData: any) {
    this.blueprintDataService.setBlueprintData(blueprintJsonData);
    this.loadBpJson = true;
  }

  subscriptionListFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.SubscriptionsListDataSource.filter = filterValue.trim().toLowerCase();
  }

  draftFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.draftStatusTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  resourceListFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.resourceDetailsTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  cloneBlueprint() {
    this.dialog.open(BlueprintCloneDialogComponent, { width: '400px', data: { subid: this.subid } });
  }
  
  changeApprovalStatus(status: string,dguid: string) {
    const dialogRef = this.dialog.open(ApprovalCommentDialogComponent, { width: '400px' });
    dialogRef.afterClosed().subscribe((comment: any) => {
      if (comment) {
        this.loadDraftStatusTable=false;
        const message = status === 'Approved' ? 'Approved Blueprint' : 'Rejected Blueprint';
        this._approvalService
          .postApproval(dguid, status, comment)
          .subscribe({
            next: () => {
              this._coreService.openSnackBar(message, 1000);
              this.getDraftsStatusTable();
            },
            error: (error) => {
              this._coreService.openSnackBar('Error in Blueprint Approval', 1000, 'warn-snackbar');
              console.error('Error in Blueprint Approval: ', error);
              this.getDraftsStatusTable();
            }
          });
      }
    });
  }
}
