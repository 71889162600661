import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AesencryptionService {

  private key = CryptoJS.enc.Utf8.parse(environment.EncryptKey);
  private iv = CryptoJS.enc.Utf8.parse(environment.EncryptIV);
  private intKey = CryptoJS.enc.Utf8.parse(environment.InternalEncryptKey);
  private intIv = CryptoJS.enc.Utf8.parse(environment.InternalEncryptIV);

  private encryptionEnabled = true; // Add this line to toggle encryption and decryption

  encryptUsingAES256(text: any): string {
    if (!this.encryptionEnabled || text === null || text === undefined) {
      return text;
    }

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  decryptUsingAES256(decString: any) {
    if (!this.encryptionEnabled || decString === null || decString === undefined) {
      return decString;
    }

    const decrypted = CryptoJS.AES.decrypt(decString, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  toggleEncryption() {
    this.encryptionEnabled = !this.encryptionEnabled;
  }

  internalEncryptUsingAES256(text: any): string {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.intKey, {
      keySize: 128 / 8,
      iv: this.intIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  internalDecryptUsingAES256(decString: string) {
    const decrypted = CryptoJS.AES.decrypt(decString, this.intKey, {
      keySize: 128 / 8,
      iv: this.intIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
