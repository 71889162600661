import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccountService {

  GET_USER_DETAILS=environment.API_URL + 'GetUserDetails';

  constructor(private http: HttpClient) { }

  getUserDetails(){
    return this.http.get(this.GET_USER_DETAILS, { observe: 'response' });
  }

  generateServiceAccountKey() {
    return this.http.post<any>(environment.API_URL + 'ServiceAccountKeyGen', null , { observe: 'response' });  
  }

  getServiceAccountKey() {
    return this.http.get<any>(environment.API_URL + 'ServiceAccountKeyGen' , { observe: 'response' });  
  }

  getServiceAccountDetails(){
    return this.http.get(environment.API_URL + 'ServiceAccount', { observe: 'response' });
  }

  activateServiceAccountDetails(body: any, resetJwtKey: boolean){
    const bodyData = {
      serviceId: body.serviceId,
      isActive: !body.isActive,
      resetJwtKey: resetJwtKey
    }
    return this.http.post(environment.API_URL + 'ServiceAccount', bodyData ,{ observe: 'response' });
  }
}
