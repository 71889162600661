import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AesencryptionService } from 'src/services/encryption.service';
import { HeaderService } from '../../shared/header/header.service';

@Injectable({
    providedIn: 'root'
})
export class ApprovalsService {
    GET_BP_REVIEW_JSON_URL = environment.API_URL + 'Review';
    GET_BP_APPROVAL_STATUS_URL = environment.API_URL + 'BluePrintApprovalStatusDetails';
    GET_BP_APPROVAL_REQUEST_LIST = environment.API_URL + 'BluePrintApprovalStatus';
    POST_BP_APPROVAL_URL = environment.API_URL + 'BluePrintApprovalSubmit';
    approvalList: any;
    constructor(private http: HttpClient, private aesencryptionService: AesencryptionService,private headerService:HeaderService) {
    }

    getBpApprovalList() {
        return this.http.get<object>(this.GET_BP_APPROVAL_REQUEST_LIST, { observe: 'response' })
    }

    getBpReviewJson(Dguid: string) {
        const params = new HttpParams()
            .set('dguid', Dguid)
        return this.http.get(this.GET_BP_REVIEW_JSON_URL, { params, observe: 'response' })
    }

    getBpApprovalStatus(Dguid: string) {
        const params = new HttpParams()
            .set('dguid', Dguid)
        return this.http.get(this.GET_BP_APPROVAL_STATUS_URL, { params, observe: 'response' })
    }

    getBpApproverStatus(Dguid: string) {
        const params = new HttpParams()
            .set('dguid', Dguid)
        return this.http.get<object>(this.GET_BP_APPROVAL_REQUEST_LIST, { params: params, observe: 'response' })
    }
    public postApproval(Dguid: string, currentStatus: string, Comments: string) {
        const body = {
            dguid: Dguid,
            approvalStatus: currentStatus,
            approvalComments: Comments,
        }
        return this.http.post(this.POST_BP_APPROVAL_URL, body, { observe: 'response' });
    }
    getandsetPendingRequestsCount(){
        this.getBpApprovalList().subscribe({
          next: (res: HttpResponse<any>) => {
            this.approvalList=res.body;
            const pendingApprovalsCount = this.approvalList.filter((item: { isApprover: any; }) => item.isApprover).length;
            const encryptedPendingApprovalsCount=this.aesencryptionService.encryptUsingAES256(pendingApprovalsCount)
            sessionStorage.setItem('pendingRequestsCount', encryptedPendingApprovalsCount);
            this.headerService.triggerRefreshHeader();
          },
          error: (error) => {
            console.error('Error fetching Approval List: ', error);
          }
        });
      }
}