import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AesencryptionService } from 'src/services/encryption.service';
import { CoreService } from '../../shared/snackbar/snackbar.service';
import { NavigationService } from 'src/services/navigation.service';
import { AddTenantDialogComponent } from '../../dialog/add-tenant-dialog/add-tenant-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { RoleData } from '../roles-table/roles';
import { TenantData } from 'src/app/models/ITenantsModel';
import { TenantsCatalogueService } from './tenants-catalogue.service';

@Component({
  selector: 'app-tenants-catalogue',
  templateUrl: './tenants-catalogue.component.html',
  styleUrls: ['./tenants-catalogue.component.scss']
})
export class TenantsCatalogueComponent {
  tenantDisplayedColumns: string[] = [];
  tenantDataSource: MatTableDataSource<TenantData> = new MatTableDataSource();
  tenantEnabledRow: any | null = null;
  tenantEditingRow: any | null = null;
  userRoles: RoleData;

  constructor(
    private _dialog: MatDialog,
    private navigationService: NavigationService,
    private _coreService: CoreService,
    private _tenantsCatalogueService: TenantsCatalogueService,
    private aesencryptionService: AesencryptionService
  ) {
    this.userRoles = JSON.parse(aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  ngOnInit(): void {
    this.navigationService.setTitle('Tenants Catalogue');
    this.getTenantFormList();
  }

  getTenantFormList(){
    this._tenantsCatalogueService.getTenantList().subscribe({
      next: (response) => {
        const TABLE_DATA: TenantData[] = response.body as TenantData[];
        if (TABLE_DATA.length > 0) {
          this.tenantDataSource = new MatTableDataSource(TABLE_DATA);
          this.tenantDisplayedColumns = TABLE_DATA[0].formList.map(col => col.resourceDisplayName);
          this.tenantDisplayedColumns.unshift('Name');
          this.tenantDisplayedColumns.push('Actions'); 
        }
      },
      error: (error) => {
        this._coreService.openSnackBar('Error while fetching tenant list', 2000, 'warn-snackbar');
        console.error('Error while fetching tenant list: ', error);
      }
    });
  }

  editRow(row: any): void {
    this.tenantEditingRow = row;
    this.tenantEnabledRow = row;
  }

  // Method to handle save button click
  saveRow(row: any) {
    var formIds = row.formList.filter((form: any) => form.isActive === true).map((form: any) => form.formId);
    this._tenantsCatalogueService.addFormToTenant(row.tenantId, formIds).subscribe({
      next: () => {
        this.getTenantFormList();
        this._coreService.openSnackBar('Form added to tenant successfully', 2000);
        this.tenantEditingRow = null; // Reset the editing row after saving
        this.tenantEnabledRow = null; // Reset the enabled row after saving
      },
      error: (error) => {
        this._coreService.openSnackBar('Error while adding form to tenant', 2000, 'warn-snackbar');
        console.error('Error while adding form to tenant: ', error);
        this.discardRow();
      }
    });
  }

  discardRow() {
    this.getTenantFormList();
    this.tenantEditingRow = null; // Reset the editing row after saving
    this.tenantEnabledRow = null; // Reset the enabled row after saving
  }

  isCheckboxDisabled(row: any): boolean {
    return this.tenantEnabledRow !== row;
  }

  isEditButtonDisabled(row: any): boolean {
    return this.tenantEditingRow !== null && this.tenantEditingRow !== row;
  }

  // Method to check if the save button should be shown
  isSaveButtonVisible(row: any): boolean {
    return this.tenantEditingRow === row;
  }
  
  openAddTenantDialog(): void {
    const dialogRef = this._dialog.open(AddTenantDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTenantFormList();
      }
    });
  }

}
