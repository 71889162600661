import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CoreService } from '../../shared/snackbar/snackbar.service';
import { ResourceDataService } from 'src/services/resource-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { BlueprintDataService } from 'src/services/blueprint-data.service';
import { NavigationService } from 'src/services/navigation.service';
import { BlueprintsService } from '../../pages/blueprint/blueprints.service';

@Component({
  selector: 'app-infra-cost-dialog',
  templateUrl: './infra-cost-dialog.component.html',
  styleUrls: ['./infra-cost-dialog.component.scss'],
})
export class InfraCostDialogComponent implements OnInit {
  tableColumns: string[] = ['name', 'type', 'cost'];
  checked = false;
  status = false;
  infracostResponseRecieved = false;
  isTemplateDraft: boolean = false;
  summary = '';
  tables = [
    {
      title: 'Subscription',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Resources to be created: ',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Resources to be updated: ',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Resources to be deleted: ',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Resources to be synced: ',
      dataSource: new MatTableDataSource<any>(),
    },
  ];
  totalCurrentCost: any;
  totalPreviousCost: any;
  subscriptionFullyQualifiedName: any;
  startTime!: number;
  dialogClosed: boolean=false;
  subUserActionType: any;
  loader!: boolean;

  constructor(
    private _blueprintService: BlueprintsService,
    private _coreService: CoreService,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<InfraCostDialogComponent>,
    private resourceDataService: ResourceDataService,
    private _blueprintDataService: BlueprintDataService,
    private navigationService:NavigationService
  ) {
    this.isTemplateDraft = data.isTemplateDraft; // Initialize the isTemplateDraft flag from the dialog data
  }

  ngOnInit() {
    this.loadCostPolling();
    this.startTime = new Date().getTime();
  }

  // Method to load cost polling
  loadCostPolling() {
    if (this.dialogClosed) return;
    this._blueprintService.getBpCostPolling(this.data.dguid).pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error Polling Costs',1000,'warn-snackbar');
        this._dialogRef.close();
        const err = ': Failed Polling Costs';
        return throwError(() => err);
       })
    ).subscribe(
      async (response: any) => {
        this.status = response.body.status;
        if (!this.status) {
          const currentTime = new Date().getTime();
          const elapsedTime = (currentTime - this.startTime) / 1000; // calculate elapsed time in seconds
          if (elapsedTime < 300) { // Condition to stop polling after 5 minutes
            await new Promise((resolve) => setTimeout(resolve, 15000));// Start polling every 15 seconds
            this.loadCostPolling();
          }
          else {
            this._coreService.openSnackBar('Time Out. Please try again later.',1000,'warn-snackbar');
            this._dialogRef.close();
          }
        } else {
          this.fetchCosts();
        }
      }
    );
  }

  close() {
    this.dialogClosed = true;
    if(!this.infracostResponseRecieved){
    this._coreService.openSnackBar('Cost Calculation has been disabled.',1000,'default-snackbar');
    }
  }

  // Method to fetch costs
  fetchCosts() {
    this._blueprintService.getBpCostJson(this.data.dguid).pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error Fetching Costs',1000,'warn-snackbar');
        const err = ': Failed Fetching Costs';
        return throwError(() => err);
       })
    ).subscribe(
      (response: any) => {
        this.isTemplateDraft =
        response.body.userRequestInfo.state === 'template';
        const infracostjson = response.body;
        this.getInfracost(infracostjson);
      }
    );
  }

  getInfracost(infracostjson:any) {
    if (!infracostjson) {
      return;
    }
    let totalPreviousCost = infracostjson.subscriptionData.resourceData.userActionType === "" ? infracostjson.subscriptionData.resourceData.currentCost : infracostjson.subscriptionData.resourceData.previousCost;
    let totalCurrentCost = infracostjson.subscriptionData.resourceData.currentCost;
    infracostjson.resourceInventoryData.forEach(
      (resourceGroup: { resourceList: any[] }) => {
        totalPreviousCost += resourceGroup.resourceList.reduce(
          (sum, resource) =>
            sum + (resource.resourceData.userActionType === "" ? resource.resourceData.currentCost : resource.resourceData.previousCost),
          0
        );
        totalCurrentCost += resourceGroup.resourceList.reduce(
          (sum, resource) => sum + resource.resourceData.currentCost,
          0
        );
      }
    );
    this.totalCurrentCost = totalCurrentCost;
    this.totalPreviousCost = totalPreviousCost;
    const result = this.resourceDataService.bpReviewData(infracostjson);
    this.subscriptionFullyQualifiedName =
      infracostjson.subscriptionData.resourceData.resourcePropertyList.find(
        (property: { propertyName: string }) =>
          property.propertyName === 'subscriptionFullyQualifiedName'
      ).propertyValue;
    infracostjson.subscriptionData.resourceData.resourceName = this.subscriptionFullyQualifiedName;
    this.subUserActionType=infracostjson.subscriptionData.resourceData.userActionType
    if (this.subUserActionType !== "") {
      this.tables[0].dataSource.data.push(infracostjson.subscriptionData.resourceData);
    }
    this.tables[1].dataSource.data = result.created;
    this.tables[2].dataSource.data = result.updated;
    this.tables[3].dataSource.data = result.deleted;
    this.tables[4].dataSource.data = result.synced;
    this.infracostResponseRecieved = true;
  }

  changeValue(value: any) {
    this.checked = !value;
  }

  submitForApproval() {
    this.loader=true;
    const dguid = this.data.dguid;
    this._blueprintService.postBPApproval(dguid, this.summary).pipe(
      catchError(error => {
        this.loader=false;
        this._dialogRef.close();
        this._coreService.openSnackBar('Error Sending Blueprint for Approval', 5000,'warn-snackbar');
        const err = ': Failed Sending Blueprint for Approval';
        return throwError(() => err);
       })
      ).subscribe(
      (response: any) => {
        const message = response.body;
        this._coreService.openSnackBar(message, 5000,'success-snackbar');
        this.loader=false;
        this._dialogRef.close();
        this._blueprintDataService.setDguid(this.data.dguid);
        this.navigationService.setDisableCanDeactivateGuard(true);
        this.route.navigateByUrl('/blueprint/explorer');
      }
    );
  }

  hasData(): boolean {
    return this.tables.some((table) => table.dataSource.data.length > 0);
  }

  getAbsoluteValue(value: number): number {
    return Math.abs(value);
  }
}
