import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'latestDate'
})
export class LatestDatePipe implements PipeTransform {

  transform(dates: number[]): number {
    if (!dates || dates.length === 0) {
      return NaN;
    }
    return Math.max(...dates);
  }

}
