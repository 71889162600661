export interface RootRolesData {
  rolesData: {
    roles: RoleData[];
  };
}
export interface RoleData {
  isSelected: boolean;
  id: string;
  roleName: string;
  serviceAccount:boolean;
  adminModule: boolean;
  adminRoleModule: boolean;
  adminDevOps:boolean;
  grafanaUser: boolean;
  blueprintView: boolean;
  blueprintCreate: boolean;
  blueprintModify: boolean;
  blueprintDelete: boolean;
  blueprintTemplateAdmin:boolean;
  blueprintApproval_L1: boolean;
  blueprintApproval_DevOps: boolean;
  formbuilderView: boolean;
  formbuilderCreate: boolean;
  formbuilderEdit: boolean;
  formbuilderTest: boolean;
  formbuilderPublish: boolean;
  azureGroups: Group[]
  isEdit: boolean;
}

export interface Group {
  groupId: string
  groupName: string
}

export interface AzGroup {
  id: string;
  displayName: string;
}


export const UserColumns : UserColumn[] = [
  {
    "Module": "Role",
    "Rights": [
      {
        "key": "roleName",
        "type": "text",
        "label": "Name",
        "required": true,
        "dependsOn": []
      }
    ],
    "id": "021f"
  },
  {
    "Module": "Administration",
    "Rights": [
      {
        "key": "adminModule",
        "type": "checkbox",
        "label": "Admins Module",
        "required": true,
        "dependsOn": []
      },
      {
        "key": "adminRoleModule",
        "type": "checkbox",
        "label": "Roles Module",
        "required": true,
        "pattern": ".+@.+",
        "dependsOn": []
      },
      {
        "key": "adminDevOps",
        "type": "checkbox",
        "label": "Devops Admin",
        "required": true,
        "pattern": ".+@.+",
        "dependsOn": []
      }
    ],
    "id": "d5f6"
  },
  {
    "Module": "Service",
    "Rights": [
      {
        "key": "serviceAccount",
        "type": "checkbox",
        "label": "User",
        "required": true,
        "pattern": ".+@.+",
        "dependsOn": []
      }
    ],
    "id": "d5f6"
  },
  {
    "Module": "Grafana",
    "Rights": [
      {
        "key": "grafanaUser",
        "type": "checkbox",
        "label": "User",
        "required": true,
        "dependsOn": []
      },
    ],
    "id": "d5f6"
  },
  {
    "Module": "Blueprints",
    "Rights": [
      {
        "key": "blueprintView",
        "type": "checkbox",
        "label": "View",
        "required": true,
        "dependsOn": []
      },
      {
        "key": "blueprintCreate",
        "type": "checkbox",
        "label": "Create",
        "required": true,
        "dependsOn": [
          "blueprintView"
        ]
      },
      {
        "key": "blueprintModify",
        "type": "checkbox",
        "label": "Modify",
        "required": true,
        "dependsOn": [
          "blueprintView", "blueprintCreate"
        ]
      },
      {
        "key": "blueprintDelete",
        "type": "checkbox",
        "label": "Delete",
        "required": true,
        "dependsOn": [
          "blueprintView", "blueprintCreate"
        ]
      },
      {
        "key": "blueprintTemplateAdmin",
        "type": "checkbox",
        "label": "Draft Admin",
        "required": true,
        "dependsOn": ["blueprintView","blueprintCreate","blueprintModify","blueprintDelete"]
      },
      {
        "key": "blueprintApproval_L1",
        "type": "checkbox",
        "label": "L1 Approver",
        "required": true,
        "pattern": ".+@.+",
        "dependsOn": ["blueprintView","blueprintCreate","blueprintModify","blueprintDelete"]
      },
      {
        "key": "blueprintApproval_DevOps",
        "type": "checkbox",
        "label": "L2 Approver",
        "required": true,
        "pattern": ".+@.+",
        "dependsOn": ["blueprintView","blueprintCreate","blueprintModify","blueprintDelete"]
      }
    ],
    "id": "d002"
  },
  {
    "Module": "Formbuilder",
    "Rights": [
      {
        "key": "formbuilderView",
        "type": "checkbox",
        "label": "View",
        "required": true,
        "dependsOn": []
      },
      {
        "key": "formbuilderCreate",
        "type": "checkbox",
        "label": "Create",
        "required": true,
        "dependsOn": ["formbuilderView"]
      },
      {
        "key": "formbuilderEdit",
        "type": "checkbox",
        "label": "Edit",
        "required": true,
        "dependsOn": ["formbuilderView"]
      },
      {
        "key": "formbuilderTest",
        "type": "checkbox",
        "label": "Test",
        "required": true,
        "dependsOn": ["formbuilderView", "formbuilderCreate", "formbuilderEdit"]
      },
      {
        "key": "formbuilderPublish",
        "type": "checkbox",
        "label": "Publish",
        "required": true,
        "dependsOn": ["formbuilderView", "formbuilderCreate", "formbuilderEdit", "formbuilderTest"],
      }
    ],
    "id": "c952"
  },
  {
    "Module": "Azure Groups",
    "Rights": [
      {
        "key": "azureGroups",
        "type": "multiselect",
        "label": "Groups Select",
        "required": true,
        "dependsOn": []
      }
    ],
    "id": "f3d8"
  },
  {
    "Module": "Actions",
    "Rights": [
      {
        "key": "isEdit",
        "type": "isEdit",
        "label": "",
        "dependsOn": []
      }
    ],
    "id": "bf89"
  }
]

export interface UserColumn  {
  Module: string
  Rights: Right[]
  id: string
}

export interface Right {
  key: string
  type: string
  label: string
  required?: boolean
  dependsOn: string[]
  pattern?: string
}