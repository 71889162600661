<div class="bg-image">
    <section class="main-div">
        <div style="position: relative; top: 30%;text-align: center;">
            <img class="unimatrix-logo" src="./assets/images/UNIMATRIX_FINAL_FULL_DARK.svg" alt=""/>
            <button mat-flat-button class="loginButton" type="submit" (click)="login()"> Continue with SSO</button>
            <img style="width: 100px; margin-top: 30px;" src="../../../../assets/images/logo-etap.svg" alt=""/>
        </div>

        <div *ngIf="isCookieDisabled" style="position: relative; top: 50%;text-align: center; padding: 2rem;">
            <p style="color: red;">Warning: Cookies are being blocked by your browser. Please enable them to improve your User Experience.</p>
        </div>
    </section>
</div>        