import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf, Subject } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { DeploymentsService } from './deployments.service';
import { RoleData } from '../roles-table/roles';
import { AesencryptionService } from 'src/services/encryption.service';
import { BlueprintIssue } from 'src/app/models/IDeploymentsModel';

@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss']
})

export class DeploymentComponent implements AfterViewInit {
  userRoles: RoleData;
  displayedColumns: string[] = ['draftSummary', 'subscriptionName', 'userName', 'subStatus', 'createdOn'];
  data: BlueprintIssue[] = [];
  tabChangeSubject: Subject<string> = new Subject<string>();
  selectedTab: string = 'Deployments';
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  tabMapping: string[] = ['Deployments', 'Queued', 'Completed'];

  filterValue = '';
  private filterSubject = new Subject<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private _deploymentService: DeploymentsService, private aesencryptionService: AesencryptionService,
  ) {
    this.userRoles = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.filterSubject, this.tabChangeSubject)
      .pipe(
        debounceTime(300),
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this._deploymentService!.getBpDeployments(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterValue,
            this.selectedTab
          ).pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data.deployments === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          this.resultsLength = data.totalCount;
          return data.deployments;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  applyFilter(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.filterValue = value.trim().toLowerCase();
    this.filterSubject.next(this.filterValue);
  }

  onTabChange(event: any): void {
    this.selectedTab = this.tabMapping[event.index] || this.tabMapping[event.index[0]];
    this.tabChangeSubject.next(this.selectedTab); // Emit the new tab value
  }
}