import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFormInput], [appFormInputBraces]'
})
export class FormInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9 _{},.-]/g, '');
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
