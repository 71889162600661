<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>{{data.message}}</div>

<div mat-dialog-actions style="justify-content: end;">
    <button mat-stroked-button [mat-dialog-close]="false">
        {{data.cancelCaption}}
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">
        {{data.confirmCaption}}
    </button>
</div>