import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'epochToHumanReadable'
})
export class EpochToHumanReadablePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) return '';

    // Convert epoch time (in seconds) to milliseconds
    const date = new Date(Number(value) * 1000);

    // Format the date to a human-readable string
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

}