import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AesencryptionService } from 'src/services/encryption.service';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    constructor(private http: HttpClient, private aesencryptionService: AesencryptionService) {
    }
    public getAppFamilyList() {   //Done
        return this.http.get(environment.API_URL + 'AdminAppFamily').pipe(map((data: object) => data));
    }
    public postAppFamily(appfamilydata: any) {
        const params = new HttpParams()
        .set('appFamilyName', appfamilydata.value.appFamilyName)
        .set('isGrafanaOrgRequired', appfamilydata.value.grafanaId);
        return this.http.post(environment.API_URL + 'AdminAppFamily', null, { params, observe: 'response' });
      }
}