import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { AzGroup, Right, RoleData, UserColumn, UserColumns } from './roles'
import { UserService } from '../../../../services/roles.service'
import { DialogService } from "src/services/confirm-dialog.service";
import { NavigationService } from 'src/services/navigation.service'
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { CoreService } from "src/app/components/shared/snackbar/snackbar.service";
import { AesencryptionService } from 'src/services/encryption.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-roles-table',
  templateUrl: './roles-table.component.html',
  styleUrls: ['./roles-table.component.scss']
})

export class RolesTableComponent implements OnInit, OnDestroy {
  displayedColumns: string[] | undefined;
  columnsSchema: any;
  dataSource = new MatTableDataSource<RoleData>()
  valid: any = {}
  allModulesName: any[] = [];
  allModules: Right[]=[];
  allRights: string[] = [];
  columnNames: any
  loadTable = false;
  loadAzGroups = false;

  azAllGroupsList: AzGroup[] = [];

  disableAdd = false;
  isEditing = false;
  isAnyCheckboxSelected=true;

  constructor(private dialogService: DialogService, private userService: UserService, private navigationService: NavigationService, private _coreService: CoreService,private aesencryptionService:AesencryptionService,
  ) { }
  public groups: FormControl = new FormControl();
  public groupsFilter: FormControl = new FormControl();
  public filteredGroupsList: ReplaySubject<AzGroup[]> = new ReplaySubject<AzGroup[]>(1);
  @ViewChild('multiSelect', { static: true }) multiSelect!: MatSelect
  protected _onDestroy = new Subject();
  ngOnInit() {
    this.navigationService.setTitle("RBAC");
    this.loadRoleTableData();
    this.getAzGroupsList();
    this.getRoleTableHeader();
    this.groups.setValue(this.azAllGroupsList[1]);
    this.filteredGroupsList.next(this.azAllGroupsList.slice());

    this.groupsFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterGroupMulti();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next(1);
    this._onDestroy.complete();
  }

  getAzGroupsList() {
    this.userService.getAzList().subscribe({
      next: (res: any) => {
        this.azAllGroupsList = res.body.value;
        this.loadAzGroups = true;
      },
      error: (error) => {
        this._coreService.openSnackBar('Error in fetching Azure Groups List', 1000, 'warn-snackbar');
        console.error('Error in fetching Azure Groups List: ', error);
      }
    });
  }

  getRoleTableHeader() {
    this.columnNames = UserColumns;
    this.columnNames.forEach((item: UserColumn) => {
      this.allModulesName.push(item.Module);
      item.Rights.forEach((rightItem: Right) => {
        this.allModules.push(rightItem);
      });
    });
    this.columnsSchema = this.allModules
    this.displayedColumns = this.allModules.map((col: { key: string }) => col.key)
  }

  loadRoleTableData() {
    this.userService.getUsers().pipe(
      map(response => response.rolesData.roles)
    ).subscribe({
      next: (roles: RoleData[]) => {
        this.dataSource.data = roles;
        this.loadTable = true;
      },
      error: (error) => {
        this._coreService.openSnackBar('Error in fetching Roles List', 1000, 'warn-snackbar');
        console.error('Error in fetching Roles List: ', error);
      }
    });
  }

  comparer(o1: AzGroup, o2: AzGroup): boolean {
    return o1 && o2 ? o1.displayName === o2.displayName : !Number.isNaN(o2);
  }
  checkValidation(row: RoleData){
    if (!this.isAnyCheckboxSelected) {
      this._coreService.openSnackBar('Please select at least one module', 5000);
      return false;
    }
    if (row.azureGroups.length === 0) {
      this._coreService.openSnackBar('Please select at least one group', 5000);
      return false;
    }
    return true;
  }
  editRow(row: RoleData) {
    if (!row.roleName) {
      this._coreService.openSnackBar('Please add role Name', 5000);
      return;
    }
    if (!this.checkValidation(row)) {
      return;
    }
    this.isEditing = false;
  
    const dialogData = this.getDialogData(row);
    this.dialogService.confirmDialog(dialogData).subscribe((confirmed) => {
      if (confirmed) {
        this.processRowUpdate(row);
      } else {
        this.cancelRowUpdate(row);
      }
    });
  }
  
  private getDialogData(row: RoleData): any {
    return row.id === "0"? {
      title: "Add Role",
      message: "On click of Add a New User Role will be created.",
      confirmCaption: "Add",
      cancelCaption: "Cancel",
    } : {
      title: "Update Role",
      message: "On click of Update User Role will be Updated.",
      confirmCaption: "Update",
      cancelCaption: "Cancel",
    };
  }
  
  private processRowUpdate(row: RoleData) {
    this.loadTable = false;
    if (this.isRoleNameUnique(row)) {
      this.updateUser(row).subscribe({
        next: () => {
          this._coreService.openSnackBar(row.id === "0"? 'Role Added Successfully' : 'Role Updated Successfully', 5000);
          this.setUserRoles();
        },
        error: (error) => {
          this._coreService.openSnackBar(row.id === "0"? 'Error in adding Role' : 'Error in updating Role', 1000, 'warn-snackbar');
          console.error(row.id === "0"? 'Error in adding Role: ' : 'Error in updating Role: ', error);
        }
      });
    } else {
      this._coreService.openSnackBar('Role already exists', 10000);
      this.loadRoleTableData();
    }
    this.disableAdd = false;
  }
  
  private cancelRowUpdate(row: RoleData) {
    this.loadTable = false;
    this.loadRoleTableData();
    if (row.id!== "0") {
      row.isEdit = false;
    }
    this.disableAdd = false;
  }
  
  private updateUser(row: RoleData): Observable<any> {
    return row.id === "0"? this.userService.addUser(row) : this.userService.updateUser(row);
  }
  

  setUserRoles() {
    this.userService.getUserRoles().subscribe({
      next: (res: HttpResponse<string>) => {
        if(res?.body)    
        sessionStorage.setItem('userRoles',res.body);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      error:(error) => {
        this._coreService.openSnackBar('Error Updating User Session', 1000, 'warn-snackbar');
        console.error('Error Updating User Session: ', error);
      }
    });
  }

  discardChanges() {
    this.loadTable = false;
    this.isEditing = false;
    this.loadRoleTableData();
    this.disableAdd = false;
  }

  addRow() {
    this.isEditing = true;
    this.disableAdd = true;
    const newRow: RoleData = {
      id: "0",
      roleName: '',
      serviceAccount:false,
      adminModule: false,
      adminRoleModule: false,
      adminDevOps:false,
      grafanaUser: false,
      blueprintView: false,
      blueprintCreate: false,
      blueprintModify: false,
      blueprintDelete: false,
      blueprintTemplateAdmin:false,
      blueprintApproval_L1: false,
      blueprintApproval_DevOps: false,
      formbuilderView: false,
      formbuilderCreate: false,
      formbuilderEdit: false,
      formbuilderTest: false,
      formbuilderPublish: false,
      azureGroups:[],
      isEdit: true,
      isSelected: false,
    }
    this.dataSource.data = [...this.dataSource.data, newRow]
    this.isAnyCheckboxSelected=false
  }

  removeRow(id: string) {
    this.dialogService
      .confirmDialog({
        title: "Remove Role",
        message: "Are you sure you want to delete the role?",
        confirmCaption: "Delete",
        cancelCaption: "Cancel",
      })
      .pipe(
        filter(confirmed => confirmed),
        switchMap(() => this.userService.deleteUser(id)),
      )
      .subscribe({
        next: () => {
          this.dataSource.data = this.dataSource.data.filter((u: RoleData) => u.id !== id.toString());
          this.setUserRoles();
        },
        error: (error) => {
          this._coreService.openSnackBar('Error in deleting role', 1000, 'warn-snackbar');
          console.error('Error in deleting role: ', error);
        }
      });
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  handleDependencies(element: any, dependencies: string[]) {
    this.isAnyCheckboxSelected=false
    if (dependencies && dependencies.length > 0) {
        dependencies.forEach((dependency: string) => {
          element[dependency]=true
          this._coreService.openSnackBar("Dependencies " + dependencies + " Automatically added", 4000, 'default-snackbar');
        })
      }
      this.isAnyCheckboxSelected = element.adminModule || element.grafanaUser || element.adminRoleModule || element.adminDevOps || element.blueprintView || element.formbuilderView;
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }
  
  protected filterGroupMulti() {
    if (!this.azAllGroupsList) {
      return;
    }
    let search = this.groupsFilter.value;
    if (!search) {
      this.filteredGroupsList.next(this.azAllGroupsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredGroupsList.next(
      this.azAllGroupsList.filter(
        (group: { displayName: string }) => group.displayName.toLowerCase().indexOf(search) > -1
      )
    );
  }
  isRoleNameUnique(newRole: RoleData): boolean {
      for(const item of this.dataSource.data){
        if(item.roleName.toLowerCase()===newRole.roleName.toLowerCase() && (item.id!==newRole.id)){
          return false
        }
      }
      return true
  }

  change(event: MatSelectChange) {
    const selectedGroups = event.value;
    const unselectedGroups = this.azAllGroupsList.filter(group => !selectedGroups.includes(group));
    this.filteredGroupsList.next([...selectedGroups, ...unselectedGroups]);
  }
  onEditClick(element: any) {
    // Get the selected values from the ngModel
     const selectedValues = element.azureGroups;
    // Create a MatSelectChange event
    const event = new MatSelectChange(selectedValues, selectedValues);
    // Call the change function with the event
    this.change(event);
  }
  
}