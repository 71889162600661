<div [class.disabled]="loader" class="dialog-container">
  <h1 mat-dialog-title style="margin-bottom:0px">Cost Breakdown
    <div *ngIf="!infracostResponseRecieved" style="font-size: 16px;opacity: 60%;">
      <p>Generating Costs for your Resources...</p>
    </div>
    <div *ngIf="loader" style="font-size: 16px;opacity: 60%;">
      <p>Submitting for Approval...</p>
    </div>
    <div *ngIf="infracostResponseRecieved" style="font-size: 16px;opacity: 60%;">
      <mat-divider></mat-divider>
    </div>
  </h1>
  <mat-dialog-content *ngIf="!infracostResponseRecieved">
    <div class="loader">
      <mat-spinner strokeWidth="4" [diameter]="50"></mat-spinner>
    </div>
  </mat-dialog-content>
  <mat-dialog-content class="mat-typography" *ngIf="infracostResponseRecieved">
    <div class="mat-content-container" *ngIf="hasData(); else noData">
      <ng-container *ngFor="let table of tables; let i = index">
        <div *ngIf="table.dataSource.data.length>0">
          <span class="table-caption">{{ table.title === 'Subscription' ? table.title + ' to be ' + subUserActionType +'d: ' : table.title }}</span>
          <table aria-label="Infracost" mat-table [dataSource]="table.dataSource" matSort>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> Type </th>
              <td mat-cell *matCellDef="let row">
                <span style="filter: opacity(70%);">{{row.resourceDisplayName}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let row">
                <span style="filter: opacity(70%);">
                  <ng-container *ngIf="(row.currentTotalCost || row.previousTotalCost); else resourceName">
                    <b>Total</b>
                  </ng-container>
                  <ng-template #resourceName>{{ row.resourceName }}</ng-template>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="cost">
              <th mat-header-cell *matHeaderCellDef> Cost </th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="!(row.currentTotalCost && row.previousTotalCost)">
                <span
                  *ngIf="row.currentCost-row.previousCost!== 0 && (row.userActionType==='update' || row.userActionType === 'delete')"
                  style="filter: opacity(70%);">
                  <span style="filter: opacity(60%);">{{row.previousCost | currency:'EUR'}}</span> -->
                </span>
                <span style="filter: opacity(70%);">{{row.currentCost | currency:'EUR'}}</span>
                <!-- This is intentional
                <span *ngIf="row.userActionType==='update' && row.previousCost!==0" style="filter: opacity(70%);">
                  <span *ngIf="row.currentCost-row.previousCost !== 0">
                    ({{ row.currentCost-row.previousCost < 0 ? '↓' : '↑'}}{{(getAbsoluteValue(row.currentCost-row.previousCost)/row.previousCost*100).toFixed(2) }}%)
                  </span>
                </span> -->
                </span>
                <span *ngIf="(row.currentTotalCost || row.previousTotalCost)">
                  <span
                    *ngIf="row.currentTotalCost-row.previousTotalCost!== 0"
                    style="filter: opacity(70%);">
                    <span style="filter: opacity(60%);">{{row.previousTotalCost | currency:'EUR'}}</span> -->
                  </span>
                  <span style="filter: opacity(70%);">{{row.currentTotalCost | currency:'EUR'}}</span>
                  <span *ngIf="row.previousTotalCost!==0 && row.currentTotalCost!==0" style="filter: opacity(70%);">
                    <span *ngIf="row.currentTotalCost-row.previousTotalCost !== 0">
                      ({{ row.currentTotalCost-row.previousTotalCost < 0 ? '↓' : '↑'}}{{(getAbsoluteValue(row.currentTotalCost-row.previousTotalCost)/row.previousTotalCost*100).toFixed(2) }}%)
                    </span>
                  </span>
                  </span>
              </td>
              <td mat-footer-cell *matFooterCellDef> 100 </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4"> No resources </td>
            </tr>
          </table>
        </div>
      </ng-container>
    </div>
    <ng-template #noData>
      <p>No modifications made to the blueprint draft</p>
    </ng-template>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions class="bottom">
    <div class="dialog-actions-container" *ngIf="infracostResponseRecieved">
    <div class="totalCost">
        <span style="color: white;" *ngIf="infracostResponseRecieved">Total</span>
        <span style="color: white;width: 100%;display: flex;justify-content: flex-end;">
          <span style="filter: opacity(60%);">{{totalPreviousCost.toFixed(2) | currency:'EUR'}}</span> -->
          <span>{{ totalCurrentCost.toFixed(2) | currency:'EUR'}}</span>
          <span *ngIf="(totalCurrentCost-totalPreviousCost) !== 0 && totalPreviousCost!==0">
            ({{ (totalCurrentCost-totalPreviousCost) < 0 ? '↓' : '↑' }}{{
              (getAbsoluteValue(totalCurrentCost-totalPreviousCost)/totalPreviousCost*100).toFixed(2) }}%) </span>
        </span>
      </div>
    <div style="padding: 0px 8px;">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Summary</mat-label>
        <input matInput placeholder="Enter Summary" [(ngModel)]="summary" #input="ngModel" [disabled]="loader" minlength="10" maxlength="200" appFormInput required>
        <mat-hint style="color: rgba(255, 255, 255, 0.6);">A short description of your Request (Min. 10 char)</mat-hint>
        <mat-error *ngIf="input.hasError('required') && (input.dirty || input.touched)"> Summary is Required
        </mat-error>
        <mat-error *ngIf="input.hasError('minlength') && (input.dirty || input.touched)"> Min. 10 characters required
        </mat-error>
      </mat-form-field>
    </div>
    </div>
      <div style="margin-left: 77%;padding: 10px;">
        <button mat-button mat-dialog-close style="margin-right: 5px;" [disabled]="loader" (click)="close()">Cancel</button>
        <span
          [matTooltip]="!summary || summary.length < 10 ?'Cannot submit,Summary is Required' : isTemplateDraft?'Cannot Submit a Template': !hasData() ? 'Cannot submit,No modifications made to draft' : ''">
          <button mat-raised-button color="primary" cdkFocusInitial
            [disabled]="isTemplateDraft || !summary || summary.length < 10 || !hasData() || loader" (click)="submitForApproval()">Submit</button>
        </span>
      </div>
  </mat-dialog-actions>
</div>
<div *ngIf="loader" class="overlay">
  <div class="loader">
    <mat-spinner strokeWidth="5" [diameter]="70"></mat-spinner>
  </div>
</div>