import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/services/navigation.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})

export class MonitoringComponent implements OnInit {
  selectedTimeRange = 1; //For One Day
  grafanaDomain = environment.GRAFANA_DOMAIN_URL;
  monitoringUrl = environment.GRAFANA_MONITORING_ENDPOINT;
  public sanitizedSource: SafeResourceUrl | undefined;


  constructor(
    private navigationService: NavigationService,
    private sanitizer: DomSanitizer,
    private cookie: CookieService,
  ) { }

  
  // iframeSrc(): SafeResourceUrl {
  //   const iframeUrl = `${this.grafanaDomain}/${this.monitoringUrl}`;
  //   try{
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  //   }catch(e){
  //     return '';
  //   }
  // }

  ngOnInit() {
    this.navigationService.setTitle('Monitoring');
    const iframeUrl = `${this.grafanaDomain}/${this.monitoringUrl}`;
    this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(
      iframeUrl) ?? undefined;
  }
  
  getSourceURL(): SafeResourceUrl | undefined {
    return this.sanitizedSource;
  }
}
