<app-navigation>
  <main>
    <article style="display: flex; margin-bottom: 10px;">
        <h2>User Roles</h2>
        <span class="example-spacer"></span>
        <button mat-raised-button color="primary" [disabled]="disableAdd" (click)="addRow()" style="width: 200px;">
        Create New User Role
        </button>
    </article>
    <mat-progress-bar *ngIf="!loadTable" mode="indeterminate"></mat-progress-bar>
    <table mat-table [ngStyle]="{'opacity': loadTable ? '1' : '0.3' }"
     class="table-container"  [dataSource]="dataSource">
     <caption></caption>

      <ng-container *ngFor="let col of columnNames" [matColumnDef]="col.Module">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="col.Rights.length">{{col.Module}}</th>
      </ng-container>
      <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">

        <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
          <span *ngSwitchDefault>{{ col.label }}</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
            <div *ngSwitchCase="'isEdit'">
              <button mat-icon-button color="primary" (click)="onEditClick(element); element.isEdit = !element.isEdit; disableAdd = true; isEditing = !isEditing" [disabled]="isEditing">
                <mat-icon>
                  <i-tabler name="edit"></i-tabler>
                </mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="removeRow(element.id)">
                <mat-icon>
                  <i-tabler name="trash"></i-tabler>
                </mat-icon>
              </button>
            </div>
            <span *ngSwitchCase="'date'">
              {{ element[col.key] | date: 'mediumDate' }}
            </span>
            <span *ngSwitchDefault>
              <div [ngSwitch]="col.type" *ngIf="!element.isEdit">

                <mat-form-field style="max-width: 185px; margin-bottom: -1.25em" appearance="outline" *ngSwitchDefault>
                  <input matInput [required]="col.required" [pattern]="col.pattern" [type]="col.type"
                    [(ngModel)]="element[col.key]" (change)="inputHandler($event, element.id, col.key)" disabled="true" maxlength="50" appFormInput/>
                </mat-form-field >

                <mat-form-field style="width: 300px; margin-bottom: -1.25em" appearance="outline" *ngSwitchCase="'multiselect'" disabled="true">
                  <mat-select 
                  multiple 
                  *ngSwitchCase="'multiselect'" 
                  [(ngModel)]="element[col.key]"
                  [compareWith]="comparer" 
                  [required]="col.required" 
                  disabled="true"
                  (click)="onEditClick(element)"
                  >
                    <mat-option 
                    *ngFor="let el of azAllGroupsList" 
                    [value]="el">
                      {{el.displayName}}
                    </mat-option>
                  </mat-select>
                  <mat-progress-bar *ngIf="!loadAzGroups" style="position: absolute" mode="indeterminate"></mat-progress-bar>
                </mat-form-field>

                <mat-checkbox *ngSwitchCase="'checkbox'" [disabled]="true"
                  [(ngModel)]="element[col.key]"></mat-checkbox>
              </div>
            </span>
          </div>
          <div [ngSwitch]="col.type" *ngIf="element.isEdit">
            <div *ngSwitchCase="'isSelected'"></div>
            <div *ngSwitchCase="'isEdit'">
              <button mat-icon-button color="primary" (click)="editRow(element)" [disabled]="disableSubmit(element.id)"
                matTooltip="Save">
                <mat-icon>
                  <i-tabler name="circle-check"></i-tabler>
                </mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="discardChanges()" [disabled]="disableSubmit(element.id)" 
              matTooltip="Discard">
                <mat-icon>
                  <i-tabler name="circle-x"></i-tabler>
                </mat-icon>
              </button>
            </div>

            <mat-checkbox color="primary" *ngSwitchCase="'checkbox'" [(ngModel)]="element[col.key]" (change)="handleDependencies(element, col.dependsOn)"></mat-checkbox>

            <mat-form-field class="rmv-field-padding" style="width: 300px;" appearance="outline" *ngSwitchCase="'multiselect'" (click)="onEditClick(element)">
              <mat-select [multiple]="true" multiple *ngSwitchCase="'multiselect'" [(ngModel)]="element[col.key]" (selectionChange)="change($event)" [compareWith]="comparer" [formControl]="groups" required>
                <mat-option style="overflow: hidden;background-color:#202320;position: sticky; top: 1;">
                  <ngx-mat-select-search [formControl]="groupsFilter"
                    [noEntriesFoundLabel]="'Not found'"  placeholderLabel="Search" maxlength="50" appFormInput></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let el of filteredGroupsList | async" [value]="el">
                  {{el.displayName}}
                </mat-option>
                <mat-option *ngFor="let el of azAllGroupsList" [value]="el">
                  {{el.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="rmv-field-padding" style="max-width: 200px;" appearance="outline" *ngSwitchDefault>
              <input matInput [required]="col.required" [pattern]="col.pattern" [type]="col.type"
                [(ngModel)]="element[col.key]" (change)="inputHandler($event, element.id, col.key)" maxlength="50" appFormInput/>
            </mat-form-field >
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="allModulesName"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <br>
  </main>
</app-navigation>