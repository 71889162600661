<app-navigation>
    <main>
        <h2>{{counter === 0 ? 'Create Form' : 'Edit Form'}}</h2>
        <mat-stepper [linear]="true" #stepper>
            <mat-step label="Form Details" [stepControl]="newresourceTemplateForm" #formStep>
                <form [formGroup]="newresourceTemplateForm" (ngSubmit)="formDetailsSubmit()">
                    <div class="form-details">
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>resourceDisplayName</mat-label>
                                <input matInput placeholder="Ex. VMs, Databases" required=""
                                    formControlName="resourceDisplayName" maxlength="50" appFormInput>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Pick Resource Type</mat-label>
                                <mat-select formControlName="resourceType" required="">
                                    <mat-option *ngFor="let item of resourcetypes" [value]="item.resourceType">{{
                                        item.resourceType}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>resourceIconUrl</mat-label>
                                <input matInput required="" formControlName="resourceIconUrl">
                                <mat-hint></mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>dependsOn</mat-label>
                                <input matInput required="" formControlName="dependsOn" maxlength="50" appFormInput>
                            </mat-form-field>
                        </div>
                        <div class="row">
                        <mat-form-field style="width: 50%;" appearance="outline">
                            <mat-label>TfState Folder Name</mat-label>
                            <input required="" matInput formControlName="tfStateFolderName" maxlength="50">
                        </mat-form-field>
                        <mat-form-field style="width: 46.5%;" appearance="outline">
                            <mat-label>Resource Name Formula</mat-label>
                            <input required="" matInput formControlName="resourceNameFormula" appFormInputBraces>
                        </mat-form-field>
                        <button mat-icon-button (click)="showResourceNameFormula=true" matTooltip="Show Hint">
                            <mat-icon>help_outline</mat-icon>
                        </button>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label>Github Reference</mat-label>
                            <input required="" matInput formControlName="gitRef" pattern="https://api\.github\.com/repos/aeco-bim-unimatrix/.*">
                            <mat-error *ngIf="newresourceTemplateForm.get('gitRef').hasError('pattern')">Invalid GitHub URL</mat-error>
                            </mat-form-field>
                    </div>
                    <div style="display: flex; justify-content: flex-end;">
                        <button mat-button type="button" (click)="goBack()">Back</button>
                        <button mat-button matStepperNext [disabled]="newresourceTemplateForm.invalid">Next</button>
                    </div>
                </form>
                <mat-card *ngIf="showResourceNameFormula" style="margin-top: 2%;filter: brightness(70%);">
                    <mat-card-header style="display: flex; justify-content: space-between; align-items: center;">
                        <mat-card-title>Resource Name Formula</mat-card-title>
                        <button mat-icon-button (click)="showResourceNameFormula=false" matTooltip="Close">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-card-header>
                    <mat-card-content>
                        <p>Resource Name Formula is a formula that is used to generate the name of the resource. The formula can
                            contain any of the following variables:</p>
                        <p>General Formula: ResourceType&#123;indexNo&#125;&#123;subscriptionIdentifier&#125;&#123;appFam&#125;&#123;applicationFamily&#125;&#123;env&#125;&#123;subscriptionName&#125;VmRole&#123;identifier&#125;</p>
                        <ul>
                            <li>Resource type: An abbreviation that represents the type of Azure resource or asset(Eg: ecl,tm,sb). This component is often a prefix or suffix in the name.</li>
                            <li>indexNo: It could be a sequential number or a unique identifier for the resource.</li>
                            <li>subscriptionIdentifier: It represents the unique identifier of the subscription under which the resource is created.</li>
                            <li>appFam: It indicates application family. It represents a group or category of applications to which the resource belongs.</li>
                            <li>applicationFamily:  Similar to appFam, it provides a more descriptive or full name of the application family.</li>
                            <li>env: It indicates the environment in which the resource is deployed, such as dev (development), ppr (pre-production), prod (production), etc.</li>
                            <li>subscriptionName: It represents the name of the subscription under which the resource is created. This helps in identifying the subscription context of the resource more descriptively.</li>
                            <li>VM role: It is an identifier of the purpose of the VM. Examples: db (database), ws (web server), ps (print server)</li>
                            <li>identifier: It indicates a unique identifier for the resource. It could be a specific name or code that uniquely identifies the resource.</li>
                        </ul>
                        <p>For example: For Windows Vm  </p>
                        <p>ResourceNameFormula: ecw&#123;indexNo&#125;&#123;appFam&#125;&#123;env&#125;&#123;identifier&#125;</p>
                        <p>Resource Name: ecw01ptdev3455</p>
                            <ul>
                                <li>ecw-Resource Type</li>
                                <li>01-IndexNo</li>
                                <li>pt-AppFam(Platform team)</li>
                                <li>dev-Environment</li>
                                <li>3455-Unique Identifier</li>
                            </ul>
                    </mat-card-content>
                </mat-card>
            </mat-step>

            <mat-step label="Field Details">
                <div class="formbuilder-grid">
                    <mat-card style="filter: brightness(120%);" class="formbuilder-table">
                        <h3>Form Fields</h3>
                        <mat-form-field appearance="outline" class="rmv-field-padding">
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="formFilter($event)" placeholder="Ex. VM" #input maxlength="50">
                        </mat-form-field>
                        <table mat-table [dataSource]="formFieldsDataSource" matSort>
                            <caption> </caption>
                            <ng-container matColumnDef="formField">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fields </th>
                                <td mat-cell *matCellDef="let row"> {{row.propertyName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="userInput">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Form Input </th>
                                <td mat-cell *matCellDef="let row"> {{row.userInput}} </td>
                            </ng-container>
                            <ng-container matColumnDef="editable">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Editable </th>
                                <td mat-cell *matCellDef="let row"> {{row.editable}} </td>
                            </ng-container>
                            <ng-container matColumnDef="itemList">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dropdown </th>
                                <td mat-cell *matCellDef="let row"> {{row.itemList}} </td>
                            </ng-container>
                            <ng-container matColumnDef="remove">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                <td mat-cell *matCellDef="let row" style="width:20%">
                                    <button mat-icon-button color="warn" (click)="removeFormField(row)"
                                        matTooltip="Remove Field from Form">
                                        <mat-icon>remove_circle</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="resourceTypeColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: resourceTypeColumns;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4"> Click the (+) button to Add Fields to the Form</td>
                            </tr>
                        </table>
                    </mat-card>

                    <mat-card style="filter: brightness(120%);" class="formbuilder-table">

                        <span class="tag">Existing Fields</span>

                        <mat-paginator #paginator [hidePageSize]="true" [showFirstLastButtons]="false"
                            [pageSizeOptions]="[10]" showFirstLastButtons>
                        </mat-paginator>

                        <div class="action-grid">
                            <mat-form-field appearance="outline" class="rmv-field-padding">
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="filterExistingFields($event)" placeholder="Ex. VM" #input maxlength="50">
                            </mat-form-field>

                            <button mat-mini-fab color="primary" (click)="addFormFieldsDialog()"
                                matTooltip="Create a Field">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>

                        <table mat-table [dataSource]="formFieldsTableSource" matSort>
                            <caption></caption>
                            <!-- Column definitions -->
                            <ng-container matColumnDef="existingField">
                                <th mat-header-cell *matHeaderCellDef>Fields</th>
                                <td mat-cell *matCellDef="let element"> {{ element.propertyName }} </td>
                            </ng-container>
                            <ng-container matColumnDef="add">
                                <th mat-header-cell *matHeaderCellDef>Add</th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button (click)="appendField(element)">
                                        <mat-icon>add_circle</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="edit">
                                <th mat-header-cell *matHeaderCellDef>Edit</th>
                                <td mat-cell *matCellDef="let element">
                                    <button color="primary" mat-icon-button (click)="editProperty(element.id)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <!-- Table rows -->
                            <tr mat-header-row *matHeaderRowDef="existingFieldColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: existingFieldColumns;"></tr>
                        </table>
                    </mat-card>
                </div>
                <mat-card-actions class="end">
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button (click)="submitEntireForm(stepper)">Next</button>
                </mat-card-actions>
            </mat-step>
            
            <mat-step label="Test Bench">
                    <mat-card style="filter: brightness(108%);">
                        <mat-card-header>
                            <mat-card-title>Default Field Values</mat-card-title>
                            <mat-card-subtitle>These are field values which are automatically filled when user logs in to Unimatrix (For Reference)</mat-card-subtitle>
                        </mat-card-header> 
                        <mat-card-content style="margin: 20px 0 0 0; display: flex; gap: 15px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Subscription Name</mat-label>
                                <input matInput placeholder="Placeholder" value="ecad-pt-dev-sandbox" [disabled]="true" maxlength="50" appFormInput>
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label>Subscription id</mat-label>
                                <input matInput placeholder="Placeholder" value="d42849a0-f922-4cc5-adef-e78facac7da5" [disabled]="true" maxlength="50" appFormInput>
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label>Ticket ID</mat-label>
                                <input matInput placeholder="Placeholder" [value]="randTicketId" [disabled]="true" maxlength="50" appFormInput>
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label>App</mat-label>
                                <input matInput placeholder="Placeholder" value="platform_team" [disabled]="true" maxlength="50" appFormInput>
                            </mat-form-field>
    
                        </mat-card-content>   
                        <mat-card-actions style="display: flex; justify-content: flex-end;">
                            <button  mat-button (click)="scrollPoint2()" [hidden]="true">Continue</button>
                        </mat-card-actions>
                    </mat-card>
    
                    <mat-card #point_2 style="filter: brightness(108%); margin-top: 15px;">
                        <mat-card-header>
                            <mat-card-title>Form Rendered</mat-card-title>
                            <mat-card-subtitle>The form fields which will be rendered in Blueprints</mat-card-subtitle>
                        </mat-card-header> 
                        <mat-card-content style="margin: 10px 0 0 0;">
                            <mat-card style="filter: brightness(115%)">
                                <mat-card-header>
                                    <mat-card-title>{{ displayName }}</mat-card-title>
                                    <mat-card-subtitle>{{resourceTypedisplayName}}</mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content style="margin: 10px 0 0 0;"> 
                                    <div class="main-body" *ngIf="currentResourcePropertyList.length>0">
                                        <form [formGroup]="inventoryformgroup" (ngSubmit)="resourceFormSubmit()">
                                          <div *ngFor="let field of currentResourcePropertyList">
                                            <div *ngIf="field.propertyNameAlias">
                                              <div *ngIf="field.itemList">
                                                <mat-form-field appearance="outline">
                                                  <mat-label>{{field.propertyNameAlias}}</mat-label>
                                                  <mat-select [formControlName]="field.propertyName">
                                                    <mat-option *ngFor="let option of field.itemList.split(';')" [value]="option">
                                                      {{ option }}
                                                    </mat-option>
                                                  </mat-select>
                                                  <mat-error>This is a mandatory Field</mat-error>
                                                </mat-form-field>
                                              </div>
                                
                                              <div *ngIf="!field.itemList">
                                                <mat-form-field appearance="outline">
                                                  <mat-label>{{field.propertyNameAlias}}</mat-label>
                                                  <input matInput type="text" [value]="field.propertyValue" [formControlName]="field.propertyName" maxlength="50">
                                                  <mat-error>This is a mandatory Field</mat-error>
                                                </mat-form-field>
                                              </div>
                                            </div>
                                          </div>
                                          <mat-divider></mat-divider>
                                          <mat-card-actions class="end">
                                            <button mat-button type="submit" color="primary"
                                              [disabled]="!inventoryformgroup.valid">Save
                                            </button>
                                          </mat-card-actions>
                                        </form>
                                      </div>
                                </mat-card-content>    
                            </mat-card>
                        </mat-card-content>                       
                    </mat-card>

                    <mat-card #point_3 style="filter: brightness(108%); margin-top: 15px;">
                        <mat-card-header>
                            <mat-card-title>Add Python Script</mat-card-title>
                            <mat-card-subtitle>The form fields which will be rendered in Blueprints</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content style="margin: 20px 0 0 0; display: flex; gap: 15px;">
                            <div class="app-ace-editor" #editor style="width: 100%;height: 550px;"></div>
                        </mat-card-content>
                        <mat-card-actions class="end">
                            <button  mat-button (click)="scrollPoint4()">Verify</button>
                        </mat-card-actions>
                    </mat-card>

                    <mat-card #point_4 style="filter: brightness(108%); margin-top: 15px;">
                        <mat-card-header>
                            <mat-card-title>Form Response</mat-card-title>
                            <mat-card-subtitle>The tfvars and txt response from the python script will be shown below</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content style="margin: 10px 0 0 0;">
                            <mat-card>
                                <div >{{scriptResult}}</div>
                            </mat-card>
                        </mat-card-content>                 
                    </mat-card>
                <div style="display: flex; justify-content: flex-end;">
                    <button mat-button color="primary">Save</button>
                </div>
            </mat-step>
        </mat-stepper>
    </main>
</app-navigation>