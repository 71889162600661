    <div style="display: flex; justify-content: space-between; align-items: center;">
        <h1 mat-dialog-title>Share Draft</h1>
        <mat-slide-toggle [(ngModel)]="isChecked" (click)="toggleActivation()" [matTooltip]="isChecked ? 'Deactivate' : 'Activate'"
        style="margin-right: 15px;"></mat-slide-toggle>
    </div>
    <p mat-dialog-content style="padding: 0px 10px 0px 20px;">On Activation and click on Submit, You can share this draft</p>
    <div mat-dialog-content>
        <div class="row">
            <mat-form-field appearance="outline" style="width: 100%; margin-top: 10px">
                <mat-label>Select a role to share</mat-label>
                <mat-select [(value)]="selected" required multiple [formControl]="newSelectControl">
                  <mat-option *ngFor="let role of shareRoles" [value]="role.roleName">
                    {{ role.roleName }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions style="justify-content: end;">
        <button mat-stroked-button type="button" (click)="dialogRef.close()">Cancel</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true" type="submit" (click)="onSubmit()" [disabled]="newSelectControl.invalid">Submit</button>
    </div>