<form [formGroup]="appfamilyform" (ngSubmit)="onFormSubmit()">
    <h1 mat-dialog-title>{{data ? 'Update': 'Create'}} an App Family </h1>
    <div mat-dialog-content>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>AppFamilyName</mat-label>
                <input matInput type="text" formControlName="appFamilyName" required="" maxlength="50">
                <mat-error *ngIf="appfamilyform.get('appFamilyName')?.hasError('required')">Required</mat-error>
                <mat-error *ngIf="appfamilyform.get('appFamilyName')?.hasError('pattern')">Incorrect pattern</mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Grafana Id</mat-label>
                <mat-select formControlName="grafanaId" required="">
                    <mat-option value="True">True</mat-option>
                    <mat-option value="False">False</mat-option>
                </mat-select>
                <mat-error *ngIf="appfamilyform.get('appFamilyName')?.hasError('required')">Required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions style="justify-content: end;">
        <button mat-stroked-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-flat-button color="primary" [mat-dialog-close]="true" type="submit"
            [disabled]="!appfamilyform.valid">{{data ? 'Update': 'Create'}}</button>
    </div>
</form>