  <form [formGroup]="resourceFormGroup" (ngSubmit)="resourceFormSubmit()">
    <h1 mat-dialog-title>Provision a New {{ displayName }}</h1>
    <mat-dialog-content style="max-height: 500px; min-height: 200px; padding-top: 5px;" class="mat-typography">
      <mat-progress-bar  *ngIf="loader" mode="indeterminate"></mat-progress-bar>
      <div class="main-body" *ngIf="currentResourcePropertyList.length>0">
          <div *ngFor="let field of currentResourcePropertyList">
            <div *ngIf="field.propertyNameAlias">
              <div *ngIf="field.itemList">
                <mat-form-field appearance="outline">
                  <mat-label>{{field.propertyNameAlias}}</mat-label>
                  <mat-select [formControlName]="field.propertyName">
                    <mat-option *ngFor="let option of field.itemList.split(';')" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                  <mat-error>This is a mandatory Field</mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="!field.itemList">
                <mat-form-field appearance="outline">
                  <mat-label>{{field.propertyNameAlias}}</mat-label>
                  <input matInput type="text" [value]="field.propertyValue" [formControlName]="field.propertyName">
                  <mat-error>This is a mandatory Field</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
      </div>
      
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button  mat-flat-button  [disabled]="isFormSubmitting || !resourceFormGroup.valid" color="primary" type="submit">Add</button>
    </mat-dialog-actions>
  </form>
