import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantsCatalogueService {

  constructor(private http: HttpClient) { }

  public getTenantList(): Observable<HttpResponse<any>> {
    return this.http.get<any>(environment.API_URL + 'TenantCatalogue', { observe: 'response' });
  }

  public createTenant(tenantName: string): Observable<any> {
    const params = new HttpParams()
    .set('tenantName', tenantName);
    return this.http.post(environment.API_URL + 'TenantCatalogue', null, { params, observe: 'response' });
  }

  public addFormToTenant(tenantId: string, formsList: any): Observable<any> {
    const params = new HttpParams()
    .set('tenantId', tenantId);
    return this.http.put(environment.API_URL + 'TenantCatalogue', formsList, { params, observe: 'response' });
  }
}
