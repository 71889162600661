import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }

  transform(v: string): SafeHtml {
    if (!v) {
      console.warn('SanitizeHtmlPipe: Input is empty or null');
      return '';
    }

    try {
      return this._sanitizer.bypassSecurityTrustHtml(v);
    } catch (e) {
      console.error('SanitizeHtmlPipe: Error sanitizing HTML', e);
      return '';
    }
  }
}