<app-navigation>
  <main>
    <h2>Third-Party Services</h2>
    <mat-progress-bar *ngIf="!userInfo"  mode="indeterminate"></mat-progress-bar>
    <section class="grid" *ngIf="userInfo">
      <mat-card>
        <mat-card-header>
          <mat-card-title>User Name: {{userInfo.UserName}}</mat-card-title>
          <mat-card-subtitle>
            Kindly Click on generate Button and copy your userID and Key
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content style="margin-top: 1rem;">
          <section style="display: grid; grid-template-columns: 0.9fr 0.1fr;">
            <mat-form-field appearance="outline">
              <mat-label>User Id</mat-label>
              <input matInput placeholder="Placeholder" [value]="userInfo.userId">
            </mat-form-field>
            <button mat-icon-button matTooltip="Copy User ID" matTooltipPosition="above"
              (click)="copyToClipboard(userInfo.userId)">
            <mat-icon><i-tabler name="copy"></i-tabler></mat-icon>
            </button>

            <mat-form-field appearance="outline">
              <mat-label>JWT Key</mat-label>
              <input matInput placeholder="Placeholder"
                [value]="jwtSecret ?'*************************************************************':''">
            </mat-form-field>
            <button mat-icon-button [disabled]="!jwtSecret" matTooltip="Copy Key" matTooltipPosition="above"
              (click)="copyJWT()">
              <mat-icon><i-tabler name="copy"></i-tabler>
              </mat-icon>
            </button>
          </section>
          <p><i>Expiry Date: </i>{{ expiryDate }}</p>
          <mat-card-actions align="end">
            <button mat-stroked-button color="warn"  (click)="activateServiceAccount(serviceAccountInfo, false)"  [disabled]="disableDeactivate">
              Deactivate
            </button>
            <button mat-raised-button color="primary" style="margin-left: 10px;" (click)="generateJwt()" [disabled]="disableGenerate">
              Generate Key
            </button>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="userRoles.adminDevOps" style="overflow: hidden;">
        <mat-card-header>
          <mat-card-title>Service Accounts List</mat-card-title>
          <mat-paginator #subscriptionPaginator="matPaginator" [hidePageSize]="true" [pageSizeOptions]="[5]" 
            style="margin-left: auto;">
          </mat-paginator>
        </mat-card-header>
        <mat-progress-bar *ngIf="loadJwtTable" mode="indeterminate"></mat-progress-bar>
        <mat-card-content>
          <table aria-label="service-account" mat-table [dataSource]="serviceAccountDataSource" style="width: 100%;">
            <ng-container *ngFor="let column of serviceAccountColumns">
              <ng-container [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef>
                  {{column.header}}
                </th>
                <td mat-cell *matCellDef="let row">
                  <ng-container [ngSwitch]="column.columnDef">
                    <ng-container *ngSwitchCase="'isActive'">
                      <button mat-stroked-button (click)="activateServiceAccount(row, false)">{{row.isActive ? 'Deactivate' : 'Activate'}}
                      </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'JwtExpiry'">
                      {{column.cell(row) | epochToHumanReadable }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{column.cell(row)}}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="serviceAccountDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: serviceAccountDisplayedColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </section>
  </main>
</app-navigation>
<router-outlet></router-outlet>