<form [formGroup]="resTypeFormGroup" (ngSubmit)="onFormSubmit()">
    <h1 mat-dialog-title>{{data ? 'Update': 'Create'}} a Resource Type </h1>

    <div mat-dialog-content>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Resource Type</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput
                    [formControl]="resourceTypecontrol" [matAutocomplete]="auto" formControlName="resourceType" maxlength="50">
                <mat-autocomplete #auto="matAutocomplete" >
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>ResourceGeneralDisplayName</mat-label>
                <input matInput formControlName="resourceGeneralDisplayName" required="" maxlength="50" appFormInput pattern="^[^http].*">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Resource Icon Content</mat-label>
                <input matInput type="text" formControlName="resourceGeneralIconUrl" required="" pattern="^[^http].*">
                <mat-hint>Upload SVG to Blob Storage & Only add svg content (Don't enter urls)</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions  style="justify-content: end;">
        <button mat-stroked-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-flat-button color="primary" [mat-dialog-close]="true" type="submit"
            [disabled]="!resTypeFormGroup.valid">{{data ? 'Update': 'Create'}}</button>
    </div>
</form>