import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-approval-comment-dialog',
  templateUrl: './approval-comment-dialog.component.html',
  styleUrls: ['./approval-comment-dialog.component.scss']
})
export class ApprovalCommentDialogComponent {
  comment='';
constructor(public dialogRef: MatDialogRef<ApprovalCommentDialogComponent>){

}

submitComment(){
  if(this.comment){
    this.dialogRef.close(this.comment);
  }
  else{
  this.dialogRef.close("No Comments");
  }
}
}
