import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { AesencryptionService } from 'src/services/encryption.service';
import { CoreService } from './components/shared/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: MsalService,
    private aesencryptionService:AesencryptionService,
    private router:Router,
    private _coreService: CoreService
  ) {}

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      sessionStorage.setItem('redirectUrl', state.url);
      // this.router.navigate(['login']);
      return false;
    }

    const roles = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
    const requiredRoles = route.data['role'];
    if (!requiredRoles) {
      return true;
    }
    
    const hasRequiredRole = Array.isArray(requiredRoles) ? requiredRoles.some(role => roles[role]) : roles[requiredRoles];
    
    if (!hasRequiredRole) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    
    return true;
  }
}