import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CoreService } from 'src/app/components/shared/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  originalSessionDetails: string | null = null;

  constructor(
    private cookieService: CookieService,
    private coreService: CoreService,
    private router: Router
  ) { }

  clearCookieAndSessionStorage() {
    sessionStorage.clear();
  }

  checkSessionStorageForChanges() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'userdetails' || event.key === 'userRoles') {
      const newSessionDetails = sessionStorage.getItem(event.key);
      if (newSessionDetails !== this.originalSessionDetails) {
        this.coreService.openSnackBar('Changes Have been made to User Details or User Roles', 2000, 'warn-snackbar');
        this.clearCookieAndSessionStorage();
        location.reload();
      }
      }
    });
  }
}
