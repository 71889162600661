import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup,Validators } from "@angular/forms";
import { MAT_DIALOG_DATA} from "@angular/material/dialog";
import { catchError, Observable, throwError } from "rxjs";
import { CoreService } from "src/app/components/shared/snackbar/snackbar.service";
import { AdminService } from "../../pages/admin/admin.service";
interface DialogData {
  appFamilyName?: string;
  grafanaId?: string;
}
@Component({
  selector: 'app-appfamily',
  templateUrl: './appfamily.component.html',
  styleUrls: ['./appfamily.component.scss']
})
export class AppfamilyComponent implements OnInit{
  appfamilyform: FormGroup;
  disablefield = true;
  filteredOptions: Observable<string[]> | undefined;
  appFamilycontrol = new FormControl("");
  options: string[] = [];

  constructor(
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _adminService: AdminService,
    private _coreService: CoreService
  ) {
    this.appfamilyform = this._fb.group({
      appFamilyName: ['',[Validators.pattern('^[A-Z][a-zA-Z]*(_[A-Z][a-zA-Z]*)*$')]],
      grafanaId: ['',Validators.required],
    });
  }
  

  ngOnInit() {
    this.appfamilyform.patchValue(this.data);
    if (this.data) {
      const appFamilycontrol = this.appfamilyform.get('appFamilyName');
      if (appFamilycontrol) {
        appFamilycontrol.disable();
      }
    }
  }
  
  onFormSubmit() {
    if (this.appfamilyform.valid) {
        this._adminService.postAppFamily(this.appfamilyform).pipe(
          catchError(error => {
            this._coreService.openSnackBar('Failure Adding App Family', 1000);
            const err = ': Failed Adding App Family';
            return throwError(() => err);
           })
        ).subscribe(
          () => {
            this._coreService.openSnackBar('Added App Family', 1000);
            window.location.reload();
          }
        );
    }
  }
}
