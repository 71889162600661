import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult,EventMessage,EventType,InteractionStatus} from "@azure/msal-browser";
import { CookieService } from "ngx-cookie-service";
import { filter } from "rxjs";
import { environment } from "src/environments/environment";
import { AesencryptionService } from "src/services/encryption.service";
import { LoginService } from "src/services/login.service";
import { CoreService } from "../../shared/snackbar/snackbar.service";
import { UserService } from "src/services/roles.service";
import { GrafanaService } from "src/services/grafana.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  loginDisplay = false;
  grafanaDomain = environment.GRAFANA_DOMAIN_URL;
  monitoringUrl = environment.GRAFANA_MONITORING_ENDPOINT;
  redirectUrl = "dashboard"

  constructor(
    private route: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cookie: CookieService,
    private loginService: LoginService,
    private coreService: CoreService,
    private userService: UserService,
    private aesencryptionService:AesencryptionService,
    private grafanaService: GrafanaService,
    private sanitizer: DomSanitizer,
    private _coreService: CoreService
  ) {}

  ngOnInit() {
    this.subscribeToLoginSuccess();
    this.subscribeToInteractionStatus();
  }

  subscribeToLoginSuccess() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });
  }

  subscribeToInteractionStatus() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.handleInteractionStatus();
      });
  }

  handleInteractionStatus() {
    this.setLoginDisplay();
    this.checkAndSetActiveAccount();

    if (this.loginDisplay) {
      this.handleLoginDisplay();
    } else {
      this.route.navigateByUrl('login');
    }
  }

  handleLoginDisplay() {
    const userDetailsCache = sessionStorage.getItem("userdetails");
    const userRolesCache = sessionStorage.getItem("userRoles");
    const navigateBasedOnUserStatus = this.navigateBasedOnUserStatus();
    if (userDetailsCache && userRolesCache) {
      this.route.navigateByUrl(navigateBasedOnUserStatus);
    } else {
      this.handleTokens();
    }
  }

  async handleTokens() {
    try {
      const response = await this.getTokens();
      if (response !== null) {
        try {
          await this.getuserdetails(response);
          const navigateBasedOnUserStatus = this.navigateBasedOnUserStatus();
          this.route.navigateByUrl(navigateBasedOnUserStatus);
        } catch (error) {
          this.route.navigateByUrl('login');
        }
      } else {
        this.route.navigateByUrl('login');
        this.coreService.openSnackBar('Error while fetching user Tokens', 2000, 'warn-snackbar');
      }
    } catch (error) {
      this.route.navigateByUrl('login');
      this.coreService.openSnackBar('Error fetching user Tokens', 2000, 'warn-snackbar');
    }
  }

  private navigateBasedOnUserStatus(): string {
    if (sessionStorage.getItem('IsNewUser') === 'true') {
      return "landing";
    } else {
      return "dashboard";
    }
  }

  async getuserdetails(responsenew: any): Promise<boolean> {
    try {
      await this.setLoginDetails(responsenew);
      await this.setUserRoles();
      return this.checkUserDetails();
    } catch (error) {
      return false;
    }
  }

  private async setLoginDetails(token: any): Promise<void> {
    try {
      const loginResponse = await this.loginService.getLogin(token).toPromise();
      if (!loginResponse?.body) {
        console.error('Login response body is undefined');
        return;
      }
  
      const decryptedBody = JSON.parse(this.aesencryptionService.decryptUsingAES256(loginResponse.body));
      if (!decryptedBody || decryptedBody.IsNewUser === undefined) {
        console.error('Decrypted body or IsNewUser property is undefined');
        return;
      }
  
      sessionStorage.setItem('IsNewUser', decryptedBody.IsNewUser);
      this.setGrafanaToken(decryptedBody);
      sessionStorage.setItem('userdetails', loginResponse.body);
    } catch (error) {
      console.error('Failed to set login details:', error);
    }
  }

  private async setUserRoles(): Promise<void> {
    try {
        const userRolesResponse = await this.userService.getUserRoles().toPromise();
        if (userRolesResponse?.body) {
            sessionStorage.setItem('userRoles', userRolesResponse.body);
        }
      } catch (error:any) {
        this._coreService.openSnackBar('Error logging In',3000,'warn-snackbar')
        this.route.navigate(['/login']);
    }
}

  private async setGrafanaToken(data: any): Promise<void> {
    await this.grafanaService.getGrafanaToken(data.Jwt).toPromise();
  }

  private checkUserDetails(): boolean {
    return sessionStorage.getItem('userdetails') !== null  && sessionStorage.getItem('userRoles') !== null;
  }

  async getTokens(): Promise<string | null> {
    try {
      const token = await this.acquireToken(environment.apiConfig.scopes[0]);
      return token;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  private async acquireToken(scope: string): Promise<string | null> {
    try {
      const result = await this.authService.acquireTokenSilent({ scopes: [scope] }).toPromise();
      return result ? result.accessToken : null;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  private handleError(error: any): void {
    if (error.errorMessage.indexOf('interaction_required') !== -1) {
      this.route.navigate(['/login']);
    } else {
      console.error(error);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  iframeSrc(): SafeResourceUrl {
    const iframeUrl = `${this.grafanaDomain}/${this.monitoringUrl}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  }
}